import { createAction } from 'typesafe-actions';

import { ToolTipDesc, ShowDescStore } from './types';

export const actions = {
  setActiveStep: createAction('workFlow/SET_ACTIVE_STEP')<number>(),
  setShowVerticalWorkFlow: createAction('workFlow/SET_SHOW_VERTICAL_WORKFLOW')<boolean>(),
  setShowHorizontalWorkFlow: createAction('workFlow/SET_SHOW_HORIZONTAL_WORKFLOW')<boolean>(),
  setPogDashboardTableHeight: createAction('workFlow/SET_POG_DASHBOARD_HEIGHT')<number>(),
  setWorkFlowToolTipDesc: createAction('workFlow/toolTipDesc/SET_TOOLTIP_DESCRIPTION')<ToolTipDesc>(),
  setShowDescStore: createAction('workFlow/toolTipDesc/SET_SHOW_TOOLTIP_DESCRIPTION')<Partial<ShowDescStore>>(),
};
