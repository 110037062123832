import { createAction } from 'typesafe-actions';

import { Checkboxes } from '../../types/models';
import { TargetBincapType } from '../../components/Assortment/assortment.type';

export const actions = {
  removeItems: createAction('assortment/REMOVE_ITEM')<string[]>(),
  restoreItems: createAction('asssortment/RESTORE_ITEMS')<string[]>(),
  changeFacings: createAction('assortment/CHANGE_FACINGS')<{
    sku: string;
    facings: number;
  }>(),
  setFacingsLock: createAction('assortment/SET_FACINGS_LOCK')<{
    sku: string;
    locked: boolean;
  }>(),

  setCustomFields: createAction('assortment/SET_CUSTOM_FIELDS')<{
    customFields: Checkboxes
  }>(),

  setToInitialAssortment: createAction('assortment/SET_TO_INITIAL')<void>(),
  setTargetBincap: createAction('assortment/SET_TARGET_BINCAP')<{
    sku: string;
    value: number;
    field: TargetBincapType;
    postSolver?: boolean;
  }>(),
};
