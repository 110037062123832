export const getFromSessionStorage = <T>(key: string): T | null => {
  const item = sessionStorage.getItem(key);

  try {
    return item === null ? item : JSON.parse(item) as T;
  } catch (error) {
    return null;
  }
};

export const setToSessionStorage = <T>(key: string, value: T): void => {
  sessionStorage.setItem(key, JSON.stringify(value));
};

export const removeFromLocalStorage = (key: string): void => {
  sessionStorage.removeItem(key);
};
