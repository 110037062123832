import React from 'react';
import { SnackbarProvider } from 'notistack';
import { CssBaseline, makeStyles, ThemeProvider, createStyles } from '@material-ui/core';
import { ConnectedRouter } from 'connected-react-router';
import { Provider as ReduxProvider } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { MsalProvider } from '@azure/msal-react';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';

import './App.css';
import { initStore } from './state';
import AuthGate from './routers/AuthGate';
import Notifier from './components/Notifier';
import ViewProvider from './provider/view.provider';
import AdminProvider from './components/AdminPage/admin.provider';
import Loading from './components/Generic/Loading';
import msalService from './services/msalService';
import theme from './helpers/theme';
import './i18n';
import { ProtoPogProvider } from './pages/ProtoPogPage/Provider/protopog.provider';
import { HomePageProvider } from './components/HomePage/Provider/HomePage.provider';

const queryCache = new QueryCache();

const { store, history } = initStore();

const useStyles = makeStyles(() =>
  createStyles({
    snack: {
      zIndex: 9999999
    },
    loaderContainer: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    }
  })
);

function App() {
  const msalInstance = msalService.getMsalInstance();
  const classes = useStyles();

  return (
    <div className="App">
      <MsalProvider instance={msalInstance}>
        <ReduxProvider store={store}>
          <DndProvider backend={HTML5Backend}>
            <AdminProvider>
              <HomePageProvider>
                <ProtoPogProvider>
                  <ViewProvider>
                    <React.Suspense
                      fallback={<Loading className={classes.loaderContainer} />}
                    >
                      <ConnectedRouter history={history}>
                        <ThemeProvider theme={theme}>
                          <SnackbarProvider
                            maxSnack={3}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }}
                            autoHideDuration={10000}
                            domRoot={document.body}
                            classes={{
                              containerRoot: classes.snack,
                            }}
                          >
                            <ReactQueryCacheProvider queryCache={queryCache}>
                              <CssBaseline />
                              <AuthGate />
                              <Notifier />
                            </ReactQueryCacheProvider>
                          </SnackbarProvider>
                        </ThemeProvider>
                      </ConnectedRouter>
                    </React.Suspense>
                  </ViewProvider>
                </ProtoPogProvider>
              </HomePageProvider>
            </AdminProvider>
          </DndProvider>
        </ReduxProvider>
      </MsalProvider>
    </div>
  );
}

export default App;
