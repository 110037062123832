import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import type { GetFixturesResponse, GetFixturesNotchesHolesResponse } from './fixtures.types';

export const actions = {
  getFixtures: createAsyncAction(
    'api/GET_FIXTURES_REQUEST',
    'api/GET_FIXTURES_SUCCESS',
    'api/GET_FIXTURES_FAILURE'
  )<void, GetFixturesResponse, AxiosError>(),
  getFixturesNotchesHoles: createAsyncAction(
    'api/GET_FIXTURES_NOTCHES_HOLES_REQUEST',
    'api/GET_FIXTURES_NOTCHES_HOLES_SUCCESS',
    'api/GET_FIXTURES_NOTCHES_HOLES_FAILURE'
  )<void, GetFixturesNotchesHolesResponse, AxiosError>(),
};

