import React, { Dispatch } from 'react';
import { AxiosError } from 'axios';

import { apiClientJava, fetchClientJava } from '../../../state';
import { Props, State, reqBody } from './protopog.types';
import { Pogs } from '../ProtoPogPage.types';
import { GetAssortmentParams, GetCurrentStateParams, GetAvailableAislesParams, GetFileParams } from '../../../types/requests';
import { actions } from '../../../state/api/actions';
import { GetParametersResponse } from '../../../types/responses';
import { mergeCurrentStateProduct } from '../../../helpers/data';
import { generateUniqueId } from '../../../helpers/utils';

export const protoPogContext = React.createContext<any>({});
export class ProtoPogProvider extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.getProtoPogItems = this.getProtoPogItems.bind(this);
    this.setFilteredPogs = this.setFilteredPogs.bind(this);
    this.getConfigurationParameters = this.getConfigurationParameters.bind(this);
    this.getAssortmentData = this.getAssortmentData.bind(this);
    this.getProductsAssortment = this.getProductsAssortment.bind(this);
    this.resetCashedPogValues = this.resetCashedPogValues.bind(this);
    this.getAisles = this.getAisles.bind(this);
    this.getFile = this.getFile.bind(this);
    this.setApiStart = this.setApiStart.bind(this);
    this.setApiFinish = this.setApiFinish.bind(this);

    this.state = {
      context: {
        api: [],
        aisles: [],
        searchedPogs: [],
        filteredPogs: [],
        getAisles: this.getAisles,
        getFile: this.getFile,
        getProtoPogItems: this.getProtoPogItems,
        setFilteredPogs: this.setFilteredPogs,
        getConfigurationParameters: this.getConfigurationParameters,
        getAssortmentData: this.getAssortmentData,
        getProductsAssortment: this.getProductsAssortment,
        resetCashedPogValues: this.resetCashedPogValues,
      },
    };
  }

  async getProtoPogItems(reqBody: reqBody) {
    this.setState((prevState: State) => ({
      context: {
        ...prevState.context,
        protoPogLoading: true,
      },
    }));

    try {
      const response: Pogs = await apiClientJava.getProtoPogs(reqBody);
      const responseKeys = response.map(p => ({ ...p, key: generateUniqueId() }));

      this.setState((prevState: State) => {
        return ({
          context: {
            ...prevState.context,
            searchedPogs: responseKeys,
            filteredPogs: responseKeys,
            protoPogLoading: false,
          },
        });
      });
    } catch {
      this.setState((prevState: State) => ({
        context: {
          ...prevState.context,
          protoPogLoading: false,
        },
      }));
    }
  }

  async getConfigurationParameters({ storeId, aisleCode }: GetAssortmentParams, dispatch: Dispatch<any>) {
    dispatch(actions.getAssortmentParams.request());

    try {
      const response: GetParametersResponse = await apiClientJava.getParameters({ storeId, aisleCode });

      dispatch?.(actions.getAssortmentParams.success(response));

      const {
        aisle_geometry: { blocks: shelfSetup },
        demand,
        side: orientation,
        use_iss: includeISS,
        use_prom: includePromo,
        mirroring_height: mirroringHeight,
        include_display: includeDisplay,
        enable_assortment_sweep: enableAssortmentSweep,
      } = response;
      const assortmentParamsData = { shelfSetup, demand, orientation, includeISS, includePromo, mirroringHeight, includeDisplay, enableAssortmentSweep };

      if (shelfSetup?.[0]?.width === 0) {
        shelfSetup[0].width = 48;
      }

      this.setState((prevState: State) => ({
        context: {
          ...prevState.context,
          assortmentConfig: {
            [`${storeId}-${aisleCode}`]: assortmentParamsData,
          },
        },
      }));

      return assortmentParamsData;
    } catch (err) {
      dispatch?.(actions.getAssortmentParams.failure(err as AxiosError));
    }
  }

  async getAssortmentData({ storeId, aisleCode }: GetAssortmentParams, dispatch: Dispatch<any>) {
    dispatch(actions.getAssortment.request());

    try {
      const response = await apiClientJava.getAssortment({ storeId, aisleCode });

      dispatch?.(actions.getAssortment.success(response));

      const { assortmentApi: { store_default: allINSKUs }} = response;

      this.setState((prevState: State) => ({
        context: {
          ...prevState.context,
          SKUs: {
            [`${storeId}-${aisleCode}`]: allINSKUs,
          },
        },
      }));

      return { allINSKUs };
    } catch (err) {
      dispatch?.(actions.getAssortment.failure(err as AxiosError));
    }
  }

  async getProductsAssortment (
    params: GetCurrentStateParams,
    dispatch: Dispatch<any>,
  ) {
    dispatch(actions.getCurrentStateProductsAssortment.request());

    try {
      const keyId = `${params.storeId}-${params.aisleCode}`;
      const response = await apiClientJava.getCurrentStateProducts(params);

      dispatch(actions.getCurrentStateProductsAssortment.success(response));

      this.setState((prevState: State) => ({
        context: {
          ...prevState.context,
          baseAssortment: {
            [keyId]: mergeCurrentStateProduct(prevState.context.baseAssortment?.[keyId] || [], response),
          },
        },
      }));

      return response;
    } catch (err) {
      dispatch(actions.getCurrentStateProductsAssortment.failure(err as AxiosError));
    }
  }

  async getAisles(params: GetAvailableAislesParams) {
    try {
      const response = await apiClientJava.getAvailableAisles(params);

      this.setState((prevState: State) => ({
        context: {
          ...prevState.context,
          aisles: response,
        },
      }));

      return response;
    } catch (err) {
      console.log(err);
    }
  }

  async getFile(params: GetFileParams) {
    const { key } = params;
    const apiName = 'getfile' + key;

    try {
      this.setApiStart(apiName);
      await fetchClientJava.getFile(params);
    } catch (err) {
      console.log(err);
    } finally {
      this.setApiFinish(apiName);
    }
  }

  setApiStart(apiName: string) {
    this.setState((prevState: State) => ({
      context: {
        ...prevState.context,
        api: prevState.context.api.concat(apiName),
      },
    }));
  }

  setApiFinish(apiName: string) {
    this.setState((prevState: State) => ({
      context: {
        ...prevState.context,
        api: prevState.context.api.filter(api => api !== apiName),
      },
    }));
  }

  setFilteredPogs(pogs: any) {
    this.setState((prevState: State) => ({
      context: {
        ...prevState.context,
        filteredPogs: pogs
      },
    }));
  }

  resetCashedPogValues() {
    this.setState((prevState: State) => ({
      context: {
        ...prevState.context,
        assortmentConfig: {},
        baseAssortment: {},
        SKUs: {},
      },
    }));
  }

  render(): JSX.Element {
    return (
      <protoPogContext.Provider value={this.state.context}>
        {this.props.children}
      </protoPogContext.Provider>
    );
  }
}
