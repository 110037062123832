import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Button, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { StoreState } from '../../state';
import { dismissNotification } from '../../state/notifications/actionCreators';
import { NotificationActionSpecification } from '../../types/models';

const Notifier = () => {
  const { notifications } = useSelector((state: StoreState) => state.notification);
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const displayedNotifications = React.useRef<(string | number)[]>([]);

  const addDisplayedNotification = (key: string | number) => {
    displayedNotifications.current.push(key);
  };

  const removeDisplayedNotification = React.useCallback(
    (keyToRemove: string | number) => {
      displayedNotifications.current = displayedNotifications.current.filter(
        key => key !== keyToRemove
      );
    },
    []
  );

  const dismissAction = React.useCallback(
    (key: string | number) => (
      <IconButton
        key="close"
        aria-label="close"
        color="inherit"
        onClick={() => closeSnackbar(key)}
      >
        <CloseIcon />
      </IconButton>
    ),
    [closeSnackbar]
  );

  const customAction = React.useCallback((actions: NotificationActionSpecification[]) => (key: string | number) => (
    <>
      {actions.map((a: NotificationActionSpecification, index: number) =>
        (<Button key={index} variant="outlined" color="primary" onClick={() => {
          a.handler();
          closeSnackbar(key);
        }}>
          {a.label}
        </Button>))}
    </>),
  [closeSnackbar]);

  React.useEffect(() => {
    notifications.forEach(notification => {
      if (
        notification.options.key == null ||
                displayedNotifications.current.includes(notification.options.key)
      ) {
        return;
      }

      // @ts-ignore
      enqueueSnackbar(notification.message, {
        ...notification.options,
        action: notification.actions ? customAction(notification.actions) : dismissAction,
        onExited: (_: HTMLElement, key: string | number) => {
          removeDisplayedNotification(key);
          dispatch(dismissNotification(key));
        }
      });
      addDisplayedNotification(notification.options.key);
    });
  }, [
    dismissAction,
    customAction,
    enqueueSnackbar,
    notifications,
    removeDisplayedNotification,
    dispatch
  ]);

  return null;
};

export default Notifier;
