import React from 'react';
import { Route, Redirect, RouteProps, useLocation } from 'react-router-dom';

import { AppRoutes } from '../utils/constants';

interface UnauthenticatedRouteProps extends RouteProps {
    component: React.ComponentType<any>;
    isAuthenticated: boolean;
    currentPath: string;
}

const UnauthenticatedRoute = (props: UnauthenticatedRouteProps) => {
  const { component: Component, isAuthenticated, currentPath, ...rest } = props;
  const { search } = useLocation();
  const url = new URLSearchParams(search);
  const startUrl = url.get('startUrl');

  return (
    <Route
      {...rest}
      render={(props): JSX.Element =>
        isAuthenticated ? (
          <Redirect to={startUrl ? startUrl : AppRoutes.Main} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default UnauthenticatedRoute;