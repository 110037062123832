import { OptionsObject } from 'notistack';

import { ResizeObserver } from '../helpers/resizeObserver';
import { Nesting, WarningsData } from '../state/job/types';
import { SelectedVendorPegboard } from '../state/vendorPegboard/types';

declare global {
  interface Window {
    env: { [key: string]: string };
    ResizeObserver?: ResizeObserver;
    __REDUX_DEVTOOLS_EXTENSION__: any;
  }
}

export type AisleProperties = {
  dimensions: ShelfBlock[];
  previousSales: number;
  wos: {
    avg: number;
    null: number;
    0: number;
    1: number;
    2: number;
    3: number;
    4: number;
  };
  facings: number[];
  dgm: number;
  fdDwoSkus: number;
  actTdSdSkus: number;
  skusWithMultiplePrimaryLocations: {
    2: number;
    3: number;
  };
  skusWithPrimaryFillIssues: number;
  skusRemoved: number;
  // TODO: move to better location
  pdfUrl: String;
  csvUrl: String;
};

export type OptimizedPogSolution = AisleProperties & {
  timestamp: number;
  images: PogImages;
};

export type OptimizationConfiguration = {
  selectedOptimizationType: OptimizationType;
  orientation: Orientation;
  demand: DemandOptions;
  labels: LabelType;
  includeISS: boolean;
  includePromo: boolean;
  mirroringHeight: number;
  weeksOfSupply: number | null;
  shelfSetup: NewShelfBlock[];
  includeDisplay: boolean;
  vendorPegboard: SelectedVendorPegboard | null;
  haloPegboard: string;
  enableAssortmentSweep: boolean;
};

type ReverseMap<T> = T[keyof T];

export type OptimizationConfigurationValues = ReverseMap<OptimizationConfiguration>;

export type Parameters = {
  side: Side;
  labels: LabelType;
  includeISS: boolean;
  includePromo: boolean;
  minimumReservedStock: number;
  aisleGeometry: NewShelfBlock[];
  demand: DemandOptions, // should uncomment after local api update
  mirroringHeight: number;
};

export type NewShelfBlock = {
  num_segments: number;
  height: number;
  depth: number;
  width: number;
  fixture_system: string;
  pegboard_config_id: null | string;
};

export type ShelfBlock = {
  order: string;
  num_sections: number;
  height: number;
  depth: number;
  width: number;
};

export enum OptimizationType {
  ByWOS = 'ideal',
  BySpace = 'fixed_size',
}

export enum Side {
  Left = 'left',
  Right = 'right',
}

export enum Orientation {
  Left = 'left',
  Right = 'right',
}

export enum DemandOptions {
  Peak = 'peak',
  Average = 'average',
}

export enum LabelType {
  ESL = 'esl',
  Regular = 'regular',
}

export enum AssortmentType {
  MyAssortment = 'MyAssortment',
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E'
}

export enum RequestStatus {
  NONE = 'NONE',
  FETCHING = 'FETCHING',
  FAILURE = 'FAILURE',
  SUCCESS = 'SUCCESS',
}

export type MetricJobProperties = {
  bincap: number,
  facings: number,
  q1f: number,
  total_commitments: number,
  wos: number,
};

export type OptimizedChanges = {
  bincap: number,
  facings: number,
  q1f: number,
  total_commitments: number,
  wos: number,
  horizontalFacing: number,
  verticalFacing: number,
  targetFacing: string,
  cappedFacingOptimized: number,
  cappedQ1fOptimized: number,
  fillTripsOptimized: number,
  bincapChange?: number,
  horizontalFacingChange?: number,
  fillTripsChange?: number,
  wosChange?: number,
  dmoq: number,
  newMinTargetBincapOptimized?: number | null,
  newMaxTargetBincapOptimized?: number | null
};

export type PogImages = {
  schematic: string;
  productImages: string;
  productImagesSkuNumbers: string;
  finelineBlocking: string;
  wosOutliers: string;
};
type AssortmentItemProperties = {
  sku: string;
  description: string,
  corpStatus: string;
  storeStatus: string,
  totalCommitments: number,
  dmoq: number;
  primaryLocation: string;
  secondaryLocations: string;
  promotable: boolean,
  campsUnitAnnul: number,
  lostSales$$: number,
  lostSalesUnits: number,
  historic: number,
  histTotalSalesDollar: number,
  forecast: number,
  totalForecast: number,
  ofPromo: number,
  pctPromoHist: number,
  dgm: number,
  permanentCount: number,

  isNewSku: boolean,
  opportunitySkuFlag: boolean,

  avgReg: number,
  avgRegIss: number,
  avgRegPromo: number,
  avgRegPromoIss: number,
  peakReg: number,
  peakRegIss: number,
  peakRegPromo: number,
  peakRegPromoIss: number,

  facings: number,
  q1f: number;
  bincap: number,
  fillTrips: number,
  wos: number,
  productEnglishLongDesc: string,

  minTargetBincap?: number | null,
  maxTargetBincap?: number | null
};

type AssortmentItemFilterProperties = {
  removed: boolean;
  multipleLocations: boolean;
  primaryFillIssue: boolean;
  lowDemand: boolean;
};

type AssortmentItemSalesForecasts = {
  avgReg: number;
  avgRegPromo: number;
  avgRegIss: number;
  avgRegPromoIss: number;
  peakReg: number;
  peakRegPromo: number;
  peakRegIss: number;
  peakRegPromoIss: number;
};

export type AssortmentItem = AssortmentItemProperties & {
  salesForecasts: AssortmentItemSalesForecasts;
  crossMerch: 'YES' | 'NO',
  isExtendedAssortment: 'YES' | 'NO',
};

export type AssortmentItemOptimized = AssortmentItem & {
  optimized?: OptimizedChanges
};

export type AssortmentItemTableRow = AssortmentItemProperties &
  AssortmentItemFilterProperties & {
    weeklyMeanSales: number;
    weeklyPeakSales: number;
    baselineWos: number | null | undefined;
    optimizedWos: number | null | undefined;
    differenceWos: number | null | undefined;
    differenceFacings: number | null | undefined;
    crossMerch: 'YES' | 'NO',
    isExtendedAssortment: 'YES' | 'NO',
    salesForecast?: SalesForecast;
    optimized?: OptimizedChanges;
  } & {
    [key: string]: any
  };

export type AssortmentTableBincapFieldType = 'min' | 'max';
export type AssortmentTableField = {
  width: number;
  row: any;
  isShowed: boolean;
  class?: string;
  isEditableBincap?: AssortmentTableBincapFieldType;
};

export type AssortmentExcelRowBaseModel = {
  sku: string;
  description: string;
  corpStatus: string;
  storeStatus: string;
  totalCommitments: string;
  primaryLocation: string;
  secondaryLocations: string;
  historic: string;
  histTotalSalesDollar: string;
  campsUnitAnnul: string;
  lostSales$$: string;
  lostSalesUnits: string;
  pctPromoHist: string;
  totalForecast: string;
  forecast: string;
  ofPromo: string;
  avgReg: string;
  avgRegPromo: string;
  avgRegIss: string;
  avgRegPromoIss: string;
  peakReg: string;
  peakRegPromo: string;
  peakRegIss: string;
  peakRegPromoIss: string;
  dgm: string;
  dmoq: string;
  promotable: string;
  bincap: string;
  q1f: string;
  fillTrips: string;
  wos: string;
  crossMerch: string;
  extendedAssortment: string;
} & {
  [key: string]: string;
};

export type PreAssortmentExcelRowIncludedModel = AssortmentExcelRowBaseModel & {
  primaryFillIssueFlag: string;
  multipleFacingFlag: string;
  skuAddedFlag: string;
  minTargetBincap?: string;
  maxTargetBincap?: string;
};

export type PostAssortmentExcelRowIncludedModel = PreAssortmentExcelRowIncludedModel & {
  optimized: {
    bincap: string;
    facings: string;
    horizontalFacing: string;
    verticalFacing: string;
    q1f: string;
    cappedFacingOptimized: number;
    cappedQ1fOptimized: number;
    fillTripsOptimized: string;
    wos: string;
    bincapChange: string;
    fillTripsChange: string;
    wosChange: string;
    minTargetBincap?: string;
    maxTargetBincap?: string;
  }
};

export type PreAssortmentExcelRowExcludedModel = AssortmentExcelRowBaseModel & {
  newFlag: string;
  opportunitySkuFlag: string;
  skusRemovedFlag: string;
};

export type PostAssortmentExcelRowExcludedModel = AssortmentExcelRowBaseModel & {
  newFlag: string;
  opportunitySkuFlag: string;
  removedByUser: string;
  removedByTetris: string;
  minTargetBincap?: string;
  maxTargetBincap?: string;
};

export type ExcelAssortmentModel = {
  included: Array<PreAssortmentExcelRowIncludedModel> | Array<PostAssortmentExcelRowIncludedModel>,
  excluded: Array<PreAssortmentExcelRowExcludedModel> | Array<PostAssortmentExcelRowExcludedModel>
};

export type ExcelAssortmentRowModel =
  | PreAssortmentExcelRowIncludedModel
  | PostAssortmentExcelRowIncludedModel
  | PreAssortmentExcelRowExcludedModel
  | PostAssortmentExcelRowExcludedModel;

export type SalesForecast = {
  avgReg: number;
  avgRegPromo: number;
  avgRegIss: number;
  avgRegPromoIss: number;
  peakReg: number;
  peakRegPromo: number;
  peakRegIss: number;
  peakRegPromoIss: number;
};

export type Solution = {
  name: string;
  timestamp: number;
  solutionId: string;
  metrics: AisleProperties;
};

export enum ForecastType {
  Reg,
  RegPromo,
  RegIss,
  RegPromoIss,
}

export type NotificationActionSpecification = {
  label: string;
  handler: () => void;
};

export type NotificationSpecification = {
  message: string;
  actions?: NotificationActionSpecification[];
  options: OptionsObject;
};

export enum JobStatus {
  None,
  Running,
}

export type ContextMenuData = {
  id: string;
  sku: string;
  x: number;
  y: number;
  width: number;
  height: number;
  horizontalFacings: number;
  verticalFacings: number;
  xPosition: number;
  yPosition: number;
  nestedHeight: number;
  nesting: Nesting;
  depth: number;
  cappedVertical: number;
  cappedOrientationCode: number;
  container: HTMLDivElement;
  isCrossbar: boolean;
  isPegboard: boolean;
  isSpecialty: boolean;
  isFlatbar: boolean;
  minBincap: number;
  maxBincap: number;
  currentBincap: number;
  targetMinBincap: number;
  targetMaxBincap: number;
  scrollHeight: number;
  pogScrollLeft: number;
  workbenchScrollLeft: number;
  isScroll: boolean;
  isSpecialtyPeg: boolean,
};

export type ContextMenuFixtureData = {
  id: number;
  x: number;
  y: number;
  container: HTMLDivElement;
};

export type ContextMenuSegmentData = {
  x: number;
  y: number;
  container: HTMLDivElement;
};

export type AssortmentFields =
  | 'description'
  | 'corpStatus'
  | 'storeStatus'
  | 'totalCommitments'
  | 'primary'
  | 'secondary'
  | 'crossMerch'
  | 'extendedAssortment'
  | 'shUnitAnnul'
  | 'sh$Annul'
  | 'shPromoPercent'
  | 'sfUnitAnnul'
  | 'campsUnitAnnul'
  | 'lostSales$$'
  | 'lostSalesUnits'
  | 'sf$Annul'
  | 'sfPromoPercent'
  | 'avgReg'
  | 'avgRegPromo'
  | 'avgRegIss'
  | 'avgRegPromoIss'
  | 'peakReg'
  | 'peakRegPromo'
  | 'peakRegIss'
  | 'peakRegPromoIss'
  | 'dgm'
  | 'dmoq'
  | 'promotable'
  | 'bincap'
  | 'q1f'
  | 'fillTrips'
  | 'wos'
  | 'historic'
  | 'forecast'
  | 'ofPromo'
  | 'facings'
  | 'bincapOptimized'
  | 'newMinTargetBincapOptimized'
  | 'newMaxTargetBincapOptimized'
  | 'totalFacingsOptimized'
  | 'horzFacingsOptimized'
  | 'vertFacingsOptimized'
  | 'q1fOptimized'
  | 'cappedFacingOptimized'
  | 'cappedQ1fOptimized'
  | 'fillTripsOptimized'
  | 'wosOptimized'
  | 'bincapChanges'
  | 'fillTripsChanges'
  | 'wosChanges'
  | 'minTargetBincap'
  | 'maxTargetBincap';

export type Checkboxes = {
  [key in AssortmentFields]?: boolean
} & {
  [key: string]: boolean
};

export type AssortmentSortProperties = AssortmentItemProperties &
{ [key: string]: number };

export type Aisle = {
  code: string;
  category: string
};

export type OptimalSpaceSegment = {
  ideal_wos: number | string,
  side: Orientation,
  demand: DemandOptions,
  labels: LabelType,
  use_iss: boolean,
  use_prom: boolean,
  aisle_geometry: {
    blocks: NewShelfBlock[],
  },
  product_nums: string[],
};

type DynamicKeys = {
  [key: string]: number;
};

export type MetricJob = {
  bincap: DynamicKeys,
  facings: DynamicKeys,
  fill_trips: DynamicKeys,
  q1f: DynamicKeys,
  total_commitments: DynamicKeys,
  wos: DynamicKeys,
};

export type MetricJobs = {
  [key: string]: MetricJob;
};
export type FetchPogByStore = {
  id: string,
  store_num: string;
  aisle_code: string;
  aisle_category: string;
  business_category: string;
  sales_in_dollars: number;
  productivity_estimate: number;
  lost_sales_total: number,
  sku_count: number;
  single_facing_sku_count: number;
  fill_trips: number;
  inv_date: string;
  delivery_date: string;
  audit: {
    created_by: string;
    created_date_time: string;
  }
};

export type AdminNotificationBanners = {
  notification_id: string,
  banner_text_eng: string,
  banner_text_fr: string,
  starts_at: string,
  expires_at: string,
  link_url: string,
  link_text_eng: string,
  link_text_fr: string,
  order: number,
};

export type AlertType = 'critical' | 'nonCritical';
export type AlertSource = 'tetris';
export type TabInfo = {
  label: string;
  type: AlertType;
  warnings?: WarningsData;
};
export type AccordionExpanded = {
  [key in AlertType]: { [key in AlertSource]: boolean };
};
export type AlertColor = {
  [key in AlertType]: { border: string, bg: string };
};

export type PogTableProperties = {
  planogramName?: boolean;
  busCat?: boolean;
  aisleNumber?: boolean;
  sales?: boolean;
  productivityEstimate?: boolean;
  campsDollar?: boolean;
  linear?: boolean;
  height?: boolean;
  depth?: boolean;
  skuCount?: boolean;
  singleFacingSkuCount?: boolean;
  currentSetupLowWosCount?: boolean;
  fillTrips?: boolean;
  lastOptDate?: boolean;
  lostSales?: boolean;
  labelSupport?: boolean;
  lostSalesDollar?: boolean;
};

export type TargetBincap = {
  product_num: string;
  min: number | null;
  max: number | null;
};

export type AssortmentDrawerChildRecord = {
  name: string;
  label: string;
  checked: boolean;
  disabled?: boolean;
};
export type AssortmentDrawerRecord = {
  caption: string;
  children: Array<AssortmentDrawerChildRecord>
};
