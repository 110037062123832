import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { VendorPegbordAPIItem } from './types';

export const actions = {
  getVendorPegboards: createAsyncAction(
    'api/GET_VENDOR_PEGBOARDS_REQUEST',
    'api/GET_VENDOR_PEGBOARDS_SUCCESS',
    'api/GET_VENDOR_PEGBOARDS_FAILURE'
  )<void, {
    [key: string]: Array<VendorPegbordAPIItem>
  }, AxiosError>(),
};
