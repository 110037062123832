// temporary default theme
import { createMuiTheme, Theme } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import { ThemeOptions } from '@material-ui/core/styles';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import merge from 'lodash.merge';

import { lgMaxContentWidth } from '../utils/constants';

const defaultTheme: Theme = createMuiTheme({});
const breakpoints = createBreakpoints({});
const customBreakpoints = {
  breakpoints: createBreakpoints({
    values: {
      ...breakpoints.values,
      md: 1024,
      sm: 834
    }
  })
};

const baseBorderWidth = 1;
const baseBorderStyle = 'solid';
const baseBorder = {
  borderWidth: baseBorderWidth,
  borderStyle: baseBorderStyle,
};

const baseThemeOptions: ThemeOptions = {
  mixins: {
    toolbar: {
      minHeight: defaultTheme.spacing(7),
    }
  },
  typography: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: 16,
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 700,
    h1: {
      fontSize: '3rem',
      lineHeight: 1.3,
      letterSpacing: 0,
    },
    h2: {
      fontSize: '24px',
      lineHeight: '28px',
      fontWeight: 700,
      letterSpacing: 0,
    },
    h3: {
      fontSize: '16px',
      lineHeight: '19px',
      fontWeight: 700,
      letterSpacing: 0
    },
    h4: {
      fontSize: '16px',
      lineHeight: '19px',
      fontWeight: 500,
      letterSpacing: 0
    },
    h6: {
      fontSize: '14px',
      lineHeight: '16px',
      fontWeight: 500,
      letterSpacing: 0,
      textTransform: 'uppercase'
    },
    body1: {
      fontSize: '14px',
      lineHeight: '16px',
      fontWeight: 400,
      letterSpacing: 0,
    },
    body2: {
      fontSize: '12px',
      lineHeight: '14px',
      fontWeight: 400,
      letterSpacing: 0,
    },
    caption: {
      fontSize: '12px',
      lineHeight: '14px',
      fontWeight: 500,
      letterSpacing: 0,
    },
    button: {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '16px',
      letterSpacing: 0,
    }
  },
  overrides: {
    MuiContainer: {
      maxWidthLg: {
        maxWidth: `${lgMaxContentWidth}!important`,
      }
    },
    MuiSvgIcon: {
      root: {
        display: 'block',
        width: '16px',
        height: 'auto',
        fontSize: 20,
      },
    },
    MuiTable: {
      root: {
        tableLayout: 'fixed',
        position: 'relative',
      },
    },
    MuiTableRow: {
      root: {
        position: 'relative',
        '&:last-child .MuiTableCell-root': {
          borderBottomWidth: 0,
        },
        '&.Mui-selected': {
          '& .MuiOutlinedInput-root': {
            color: '#fff'
          },
          '& .Mui-checked': {
            backgroundColor: '#fff',
            color: '#009688'
          },
          '&:hover': {
            backgroundColor: '#00796B'
          },
          '& .MuiCheckbox-root svg': {
            margin: -4 // to make a white checkmark
          },
          '& .MuiCheckbox-root': {
            marginLeft: 4
          },
          '& .MuiTableCell-body': {
            color: '#fff',
            '& .MuiCheckbox-colorPrimary.Mui-checked': {
              color: '#009688'
            }
          }
        }
      },
      head: {
        '&:last-child .MuiTableCell-root': {
          borderBottomStyle: baseBorderStyle,
          borderBottomWidth: baseBorderWidth,
        },
      },
      hover: {
        cursor: 'pointer',
      }
    },
    MuiTableContainer: {
      root: {
        ...baseBorder,
        borderRadius: defaultTheme.shape.borderRadius,
      },
    },
    MuiTableCell: {
      root: {
        padding: defaultTheme.spacing(2, 2.5),
        position: 'relative',
      },
      head: {
        fontWeight: 500,
        borderRightStyle: baseBorderStyle,
        borderRightWidth: baseBorderWidth,
        borderBottomStyle: baseBorderStyle,
        borderBottomWidth: baseBorderWidth,
        '&:last-child': {
          borderRight: 'none',
        },
      },
    },
    MuiFormControlLabel: {
      labelPlacementStart: {
        marginLeft: 0,
        marginRight: 0,
        '& > .MuiFormControlLabel-label': {
          marginLeft: 0,
          marginRight: 10
        }
      },
      root: {
        marginLeft: 0,
        marginRight: 0,
      },
      label: {
        marginLeft: 10
      }
    },
    MuiFormLabel: {
      root: {
        '&.Mui-focused': {
          color: '#9e9e9e'
        }
      }
    },
    MuiCheckbox: {
      root: {
        padding: 0,
        '& svg': {
          width: 22
        },
        '&.MuiCheckbox-colorPrimary.Mui-disabled': {
          color: '#E0F2F1'
        }
      }
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0
      }
    },
    MuiListItem: {
      root: {
        paddingTop: 0,
        paddingBottom: 0
      },
      gutters: {
        paddingLeft: 0,
        paddingRight: 0
      }
    },
    MuiIconButton: {
      root: {
        padding: '0',
        '&:hover': {
          backgroundColor: 'transparent'
        }
      },
    },
    MuiRadio: {
      root: {
        color: '#bdbdbd',
        padding: 0,
        marginRight: 0
      },
      colorSecondary: {
        '&$checked .MuiSvgIcon-root:last-child': {
          color: '#00796B'
        },
        '&$checked.Mui-disabled .MuiSvgIcon-root:last-child': {
          color: '#E0F2F1'
        },
        '&$checked .MuiSvgIcon-root:first-child': {
          color: '#bdbdbd'
        },
      }
    }
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    }
  }
};

const palette: PaletteOptions = {
  secondary: grey,
  divider: grey[300],
  background: {
    default: '#F5F5F5',
  },
};

const detailThemeOptions: ThemeOptions = {
  palette,
  overrides: {
    MuiTypography: {
      colorTextSecondary: {
        color: '#757575',
      },
    },
    MuiBreadcrumbs: {
      li: {
        '& > a': {
          fontSize: '12px',
          lineHeight: '14px',
          fontWeight: 500,
          color: '#00796B',
          textDecoration: 'none'
        }
      }
    },
    MuiButton: {
      root: {
        textTransform: 'unset',
        fontSize: 14,
        minWidth: 1,
        fontWeight: 500,
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: 'transparent',
          boxShadow: 'none'
        },
        '&.MuiButton-containedPrimary:disabled .MuiButton-startIcon svg > path': {
          stroke: '#E0F2F1'
        },
      },
      textPrimary: {
        '&:disabled .MuiButton-startIcon svg > path': {
          stroke: '#E0F2F1'
        },
        '&:disabled .MuiButton-startIcon svg > circle': {
          stroke: '#E0F2F1'
        },
        '&:hover': {
          backgroundColor: 'unset'
        }
      },
      endIcon: {
        marginLeft: 10,
        marginRight: 0
      },
      startIcon: {
        marginLeft: 0,
        marginRight: 6
      },
      outlinedPrimary: {
        background: '#fff',
        padding: '8px 15px',
        height: 32,
        border: '1px solid #00796B'
      },
      outlinedSecondary: {
        background: '#fff',
        padding: '8px 15px',
        height: 32,
        color: '#9e9e9e',
        '&:hover': {
          backgroundColor: '#eee',
          borderColor: '#eee',
          '& .MuiSvgIcon-root > path': {
            stroke: '#eee'
          }
        },
      },
      containedPrimary: {
        background: '#00796B',
        padding: '8px 15px',
        height: 32,
        boxShadow: 'none',
        color: '#fff',
        border: '1px solid #00796B',
        '& .MuiSvgIcon-root > path': {
          stroke: '#00796B'
        },
        '& .MuiSvgIcon-root > circle + path': {
          stroke: 'unset',
          fill: '#fff'
        },
        '&:active': {
          backgroundColor: '#4DB6AC',
          borderColor: '#4DB6AC',
          '& .MuiSvgIcon-root > path': {
            stroke: '#4DB6AC!important'
          },
          '& .MuiSvgIcon-root > circle + path': {
            stroke: 'unset',
            fill: '#fff'
          },
        },
        '&:disabled': {
          backgroundColor: '#E0F2F1',
          borderColor: '#E0F2F1',
          color: '#fff'
        },
        '&:hover': {
          backgroundColor: '#009688',
          boxShadow: 'none',
          '& .MuiSvgIcon-root > path': {
            stroke: '#009688'
          },

          '& .MuiSvgIcon-root > circle + path': {
            stroke: 'unset',
            fill: '#fff'
          },
        }
      },
      text: {
        padding: 0,
        '&.MuiButton-textPrimary': {
          color: palette.primary,
          padding: 0,
          boxShadow: 'none'
        }
      },
    },
    MuiTableContainer: {
      root: {
        borderColor: palette.divider,
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: '8px 10px',
        height: 'initial'
      },
      notchedOutline: {
        borderWidth: '1px!important',
        left: '-1px',
        right: '-1px',
        top: '-6px',
        bottom: '-1px',
        borderColor: '#9e9e9e'
      },
    },
    MuiTableRow: {
      root: {
        borderBottomStyle: baseBorderStyle,
        borderBottomWidth: baseBorderWidth,
      },
      head: {
        backgroundColor: grey[100],
      },
    },
    MuiTableCell: {
      head: {
        color: grey[500],
        borderBottomColor: palette.divider,
        borderRightColor: palette.divider,
        '&:nth-last-child(2)': {
          borderRight: 'none'
        }
      },
      body: {
        color: grey[600],
        '&:not(:last-child)': {
          borderRight: '1px solid #e0e0e0'
        }
      },
    },
    MuiTablePagination: {
      root: {
        borderTopColor: palette.divider,
        color: grey[500]
      },
    },
    MuiFormControlLabel: {
      root: {
        margin: 0
      }
    },
    MuiSwitch: {
      root: {
        height: 16,
        width: 28,
        padding: 0
      },
      colorSecondary: {
        '&.Mui-checked + .MuiSwitch-track': {
          backgroundColor: '#009688'
        },
        '&.Mui-checked': {
          color: '#fff'
        },
        '&.Mui-disabled': {
          color: '#fff'
        }
      },
      input: {
        width: '200%',
        left: 0
      },
      thumb: {
        width: 14,
        height: 14,
        boxShadow: '0px 0.5px 2px rgba(0, 0, 0, 0.3)'
      },
      switchBase: {
        padding: 0,
        top: 1,
        left: 1,
        '&.Mui-checked': {
          transform: 'translateX(calc(100% - 2px))'
        },
        '&.Mui-checked + .MuiSwitch-track': {
          opacity: 1
        },
        '&.Mui-disabled + span.MuiSwitch-track': {
          backgroundColor: '#BDBDBD',
          opacity: 1
        }
      },
      track: {
        opacity: 1,
        backgroundColor: '#BDBDBD',
        boxShadow: '0px 0px 2px rgba(151, 151, 151, 0.25)',
        borderRadius: 10
      }
    },
    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: 'none',
          transitionProperty: 'none',
        }
      }
    },
    MuiListItemText: {
      root: {
        marginTop: 0,
        marginBottom: 0
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 'unset'
      }
    }
  }
};

export const theme: Theme = createMuiTheme(
  merge(
    {},
    baseThemeOptions,
    detailThemeOptions,
    customBreakpoints
  )
);

export default theme;