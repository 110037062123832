import { ApiSolverOutputInputParameters } from '../../types/api';
import { LabelType, DemandOptions, Side } from '../../types/models';
import type { BlockingAttributes } from '../blocking/types';
import { FixtureSystemNotchesHoles } from '../fixtures/fixtures.types';

export enum OptimizationStatus {
  queued = 'queued',
  started = 'started',
  done = 'done',
  done_no_solution = 'done_no_solution',
  failed = 'failed',
  killed = 'killed',
  infeasible = 'infeasible'
}

export enum LocatedType {
  shelf = 'SHELF',
}

export type Job = {
  job_id: string;
  parent_job_id: string | null;
  mirrored_parent_id: string | null;
  status: OptimizationStatus;
  user_id: string;
  test_group: string | null;
  test_group_timestamp: string | null;
  store_num: number;
  aisle_code: string;
  created_at: string;
  updated_at: string;
  numberOfPolls: number;
  timeOfLastCall: Date;
  type: string;
};

export type Jobs = Array<Job>;

export type latestJob = {
  error: boolean;
  errorMessage: string;
  responseData: Job;
};

export type JobsState = Jobs;

export type JobState = Partial<Job>;

export type Block = {
  num_segments: number;
  height: number;
  depth: number;
  width: number;
  fixture_system: FixtureSystemNotchesHoles;
  pegboard_config_id: null | string;
};

export type LocatedFixture = {
  id: number;
  fixture_id: number | string;
  x: number;
  y: number;
  z: number;
  width: number;
  height: number;
  depth: number;
  type: string,
  segment: number;
  grille_height: number;
  is_display_only: boolean;
  shelf_slope: number;
  is_base_shelf: boolean,
  is_merchandisable: boolean,
  is_dummy: boolean | null
};

export type AisleGeometry = {
  blocks: Block[]
};

export type TransformPeghole = {
  x: number;
  y: number;
  width: number;
  height: number;
  centerX: number;
  centerY: number;
};

export type TransformPegholes = Array<TransformPeghole>;

export type Peghole = {
  peghole_x: number;
  peghole_y: number;
  peghole_width: number;
};

export type Dimensions = {
  width: number;
  height: number;
  depth: number;
};

export type Packaging = Dimensions & {
  merch_style: string;
  num_units: number;
  nested_height: number;
  nested_depth: number;
  pegholes: Array<Peghole>;
  peggable: boolean;
  weight: number;
};

export enum Nesting {
  None = 'NONE',
  YNested = 'Y_NESTED',
  ZNested = 'Z_NESTED',
  YCapped = 'Y_CAPPED',
}

export type FacingGroup = {
  located_fixture_id: number;
  product_num: string;
  x: number;
  y: number;
  horizontal_facings: number;
  vertical_facings: number;
  depth_facings: number;
  orientation_code: number;
  segment: number;
  packaging: Packaging;
  cap_nested: string;
  capped_horizontal_facings: number;
  capped_vertical_facings: number;
  capped_depth_facings: number;
  capped_orientation_code: number;
  id: string;
  cappedPackaging: Dimensions;
  defaultPackaging: Packaging;
  fixtureType: string;
  peg_id: null | string;
};

export type SkuMetrics = {
  isProtoC: boolean;
  fcst: number | null;
  totalComm: number | null;
  weight: number | null;
  bincap: number | null;
  historicTotalSales: number | null;
};

export type FacingGroupCipboard = FacingGroup & {
  isProtoC: boolean;
  fcst: number | null;
  totalComm: number | null;
  productName: string;
};

export type ClipboardFixtureFacings = {
	segment: number;
	y: number;
	type: string;
	facings: Array<FacingGroupCipboard>
};

export type FacingGropOrigin = Omit<FacingGroup, 'cappedPackaging' | 'fixtureType' | 'defaultPackaging'>;

export type Product = {
  product_num: string;
  product_english_desc: string;
  product_english_long_desc: string;
  fineline_cd: string;
  fineline_nm: string;
  brand: string;
  corporate_status_cd: string;
  dmoq: number;
  width: number;
  height: number;
  depth: number;
  units_per_case: number;
  max_stackable: number;
  'image-url': string;
  alt_orientations: Array<number>;
};

export type Products = Record<string, Product>;
export type ProductsList = Array<string>;

export type Attribute = {
  value: string;
  color: string;
};

export type AtributeElement = { unique: string, name: string };
export type Attributes = Record<string, Array<string>>;
export type AttributeProduct = Record<string, AtributeElement>;
export type AttributeColor = string;
export type AttributesProducts = Record<string, AttributeProduct>;
export type AttributesColors = Record<string, Record<string, AttributeColor>>;

export type NormalizeProducts = {
  products: Products;
  productsList: Array<string>;
};

export type Planogram = {
  aisle_geometry: AisleGeometry;
  facing_groups: Array<FacingGroup>;
  located_fixtures: Array<LocatedFixture>;
  side: 'left' | 'right';
  products: Products;
  productsList: Array<string>;
  blocking_attributes: BlockingAttributes;
};

export type PlanogramState = Partial<Planogram>;

export interface ObjectWithDynamicStringKeyNumberValue {
  [key: string]: number;
}

export enum Filters {
  AssortmentSweep = 'assortmentSweep',
  PrimaryFillIssue = 'primaryFillIssue',
  MultipleHomes = 'multipleHomes',
  DWOFD = 'dwofd',
  MoreThanOne = 'moreThanOne',
  ACTTDSD = 'acttdsd',
  CrossMerch = 'crossMerch',
  New = 'new',
  ExtendedAssortment = 'extendedAssortment'
}

export type PlanogremFilterState = {
  filterInfo: string;
  filter: string[];
};
export interface ObjectWithDynamicKeys {
  [key: number]: number;
}
export type MetricJobState = {
  bincap: ObjectWithDynamicStringKeyNumberValue,
  facings: ObjectWithDynamicStringKeyNumberValue,
  q1f: ObjectWithDynamicStringKeyNumberValue,
  total_commitments: ObjectWithDynamicStringKeyNumberValue,
  wos: ObjectWithDynamicStringKeyNumberValue,
  fill_trips: ObjectWithDynamicStringKeyNumberValue,
};

export type ProductsSolverOutput = {
  product_num: string;
  product_english_desc: string;
  product_english_long_desc: string;
  fineline_cd: string;
  fineline_nm: string;
  brand: string;
  corporate_status_cd: string;
  dmoq: number;
  width: number;
  height: number;
  depth: number;
  units_per_case: number;
  max_stackable: number;
  'image-url': string;
  alt_orientations: Array<number>;
};

export type LocatedFixtureSolverOutput = {
  id: number;
  located_fixture_id: number;
  x: number;
  y: number;
  width: number;
  height: number;
  depth: number;
  type: string,
  segment: number;
};

export type BlockingAttributesSolver = {
  names: string[];
  values: object;
};

export type LeadIn = {
  segment_start: number;
  segment_end: number;
  text: string;
};

export type Pop = {
  proto_x: number;
  proto_y: number;
  width: number;
  text: string;
  is_pop: boolean;
};

export type PlanogramSolverOutput = {
  aisle_code: string;
  size: string;
  labels: LabelType;
  proto_pog_id: string;
  aisle_geometry: AisleGeometry;
  facing_groups: Array<FacingGroup>;
  clipboard?: Array<FacingGroup>;
  clipboardFixtures?: Array<LocatedFixture>;
  clipboardFixturesFacings?: Array<FacingGroup>;
  located_fixtures: Array<LocatedFixture>;
  side: 'left' | 'right';
  products: Array<ProductsSolverOutput>;
  blocking_attributes: BlockingAttributes;
  lead_ins: Array<LeadIn>;
  merch_notes: string;
  pops: Array<Pop>;
  workbench?: Array<FacingGroup>;
};

export type BincapRecord = {
  product_num: string;
  min: null | number;
  max: null | number;
};

export type EnforcedBincap = {
  enforced_bincaps: Array<BincapRecord>,
  enforced_bincaps_locked?: Array<BincapRecord>,
};

export type SolverInput = {
  name: string;
  parameters: ApiSolverOutputInputParameters;
  product_nums: string[];
  constraints: null | EnforcedBincap;
};

export interface MissingBlockingDataWarnings {
  blocking_attribute_name: string;
}

export interface MissingBlockingDataWarningsRecord
  extends MissingBlockingDataWarnings {
  warningType: string;
  selected: boolean;
}

export interface ProductMissingBlockingDataWarnings {
  blocking_attribute: string;
  product_nums: string[];
}

export interface ProductMissingBlockingDataWarningsRecord
  extends ProductMissingBlockingDataWarnings {
  warningType: string;
  selected: boolean;
}

export interface HeavyProductHeightWarnings {
  product_num: string;
  fixture_id: string;
  x: number;
  y: number;
}

export interface MoveBasketToSelfWarnings {
  product_num: string;
  from_fixture_type: string;
  to_fixture_type: string;
  x: number;
  y: number;
}

export interface MoveCrossbarToPegWarnings {
  product_num: string;
  from_fixture_type: string;
  to_fixture_type: string;
  x: number;
  y: number;
}

export interface MoveBasketToSelfWarningsRecord
  extends MoveBasketToSelfWarnings {
  warningType: string;
  selected: boolean;
}

export interface MoveCrossbarToPegWarningsRecord
  extends MoveCrossbarToPegWarnings {
  warningType: string;
  selected: boolean;
}

export interface HeavyProductHeightWarningsRecord
  extends HeavyProductHeightWarnings {
  warningType: string;
  selected: boolean;
}

export interface ProductPackagingWarnings {
  product_num: string;
  selected_merch_style: string;
  possible_merch_styles: string[]
}

export interface ProductPackagingWarningsRecord
  extends ProductPackagingWarnings {
  warningType: string;
  selected: boolean;
}

export interface LinearWhitespaceWarnings {
  x: number;
  y: number;
  width: number;
  located_fixture_ids: number[];
}

export interface LinearWhitespaceWarningsRecord
  extends LinearWhitespaceWarnings {
  warningType: string;
  selected: boolean;
}

export interface EmptyFixtureWarnings {
  located_fixture_id: number;
}

export interface EmptyFixtureWarningsRecord extends EmptyFixtureWarnings {
  warningType: string;
  selected: boolean;
}

export interface TooMuchSupplyWarnings {
  product_num: string;
  exceeding_facings: number;
  exceeding_units: number;
  estimated_units_by_facing: number;
}

export interface TooMuchSupplyWarningsRecord
  extends TooMuchSupplyWarnings {
  warningType: string;
  selected: boolean;
}

export interface ExcludedProductWarnings {
  product_num: string;
  exclusion_reason: string;
  in_clipboard: boolean;
}

export interface ExcludedProductWarningsRecord
  extends ExcludedProductWarnings {
  warningType: string;
  selected: boolean;
}

export interface WeightRestrictionWarnings {
  fixture_id: number;
  capacity: number;
  total_weight: number;
}

export interface ExtendedWeightRestrictionWarnings extends WeightRestrictionWarnings {
  attribute: string;
  fixtureCaption: string;
  weightCaption: string;
}

export interface WeightRestrictionWarningsRecord extends WeightRestrictionWarnings {
  warningType: string;
  selected: boolean;
}

export interface SafetyRestrictionWarning {
  warningType: string;
  selected: boolean;
}

export interface FdDwoBincapWarnings {
  product_num: string;
  x: number;
  y: number;
  commitment: number;
  bincap: number;
}

export interface FDDwoBincapWarningsRecord
  extends FdDwoBincapWarnings {
  warningType: string;
  selected: boolean;
}

export interface AssortmentSweepWarnings {
  product_num: string;
}

export type WarningInfo =
  | HeavyProductHeightWarningsRecord
  | ProductPackagingWarningsRecord
  | ProductMissingBlockingDataWarningsRecord
  | LinearWhitespaceWarningsRecord
  | TooMuchSupplyWarningsRecord
  | ExcludedProductWarningsRecord
  | WeightRestrictionWarningsRecord;

export type WarningData = {
  warningType: string;
  selected: boolean;
  data:
    | HeavyProductHeightWarnings[]
    | ProductPackagingWarnings[]
    | ProductMissingBlockingDataWarnings[]
    | LinearWhitespaceWarnings[]
    | TooMuchSupplyWarnings[]
    | ExcludedProductWarnings[]
    | MoveBasketToSelfWarnings[]
    | MoveCrossbarToPegWarnings[]
    | WeightRestrictionWarningsRecord[]
    | FdDwoBincapWarnings[]
    | AssortmentSweepWarnings[];
};

export type WarningsData = Array<WarningData>;

export type Warnings = {
  heavy_product_height_warnings: HeavyProductHeightWarnings[] | [];
  product_packaging_warnings: ProductPackagingWarnings[] | [];
  product_missing_blocking_data_warnings: ProductMissingBlockingDataWarnings[] | [];
  linear_whitespace_warnings: LinearWhitespaceWarnings[] | [];
  too_much_supply_warnings: TooMuchSupplyWarnings[] | [];
  excluded_product_warnings: ExcludedProductWarnings[] | [];
  weight_restriction_warnings: WeightRestrictionWarnings[] | [];
  assortment_sweep_warnings: AssortmentSweepWarnings[] | [];
};

export type SolverOutputType = {
  job_id: string;
  parent_job_id: string;
  mirrored_parent_id: string;
  planogram: PlanogramSolverOutput;
  solver_input: SolverInput;
  warnings: Warnings;
  user_warnings: null | Array<WarningData>;
  user_notes: string;
  excluded_product_warnings: null | Array<WarningData>;
};

export type SolverOutputState = Partial<SolverOutputType>;

export interface ISolutions {
  solutionId: string;
  skus: string;
  salesType: string;
  date: string;
  checked: boolean;
  dimensions: string;
}

export type Point = {
  x: number;
  y: number;
};

export type ProductsState = Products;
export type ProductsListState = ProductsList;
export type PackagingState = Record<string, Packaging>;

export type ProductInfo = {
  isProtoC: boolean;
  fcst: number | null;
  bincap: number | null;
  weight: number | null;
  historicTotalSales: number | null;
};

export type LeadInInfo = {
  segment: number;
  text: string;
};

export interface IPogAssortmentConfig {
  orientation: Side;
  demand: DemandOptions;
  labels?: LabelType;
  includeISS: boolean;
  includePromo: boolean;
  shelfSetup: Block[];
}

export enum PageSection {
  Configuration,
  Compare,
  Visualization
}