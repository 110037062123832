import { batch } from 'react-redux';
import { AuthenticationResult } from '@azure/msal-browser';
import { AxiosError } from 'axios';

import { actions } from './actions';
import { ThunkActionCreator } from '..';
import {
  LaunchOptimizationJobParams,
  GetOptimizationStatusParams,
  GetAssortmentParams,
  GetAvailableAislesParams,
  GetCurrentStateParams,
  getOptimizationJobParams,
  getOptimizationJobProtoParams,
  GetMetricsForJobIdParams,
  GetSolverOutputParams,
  GetEditedJobParams,
  GetEditedMetricsPogParams,
  GetEditedMetricsProductParams,
  GetEditedSolverOutputParams,
  SaveNewEditedPogParams,
  getFetchPogByStoreParams,
  AggregatedJobsParams,
  AggregatedSolverOutputParams,
  AggregatedProductMetricsParams,
  AggregatedPogMetricsParams,
  ProductsInfoParams,
  ProductsPackagingParams,
  SaveUserPreferenceParams,
  SaveMyNotesParams,
  DownloadAssortmentExcelFileParam,
  DownloadOutputFileParam,
  DownloadDOSFileParam,
  FlipPogParams,
} from '../../types/requests';
import { LatestJobs } from '../../types/responses';
import msalService from '../../services/msalService';
import { formatDateToYYYYMMDD } from '../../helpers/data';

export const getAvailableStores: ThunkActionCreator = () => async (
  dispatch,
  _,
  { apiClientJava }
) => {
  dispatch(actions.getAvailableStores.request());

  try {
    const { responseData, admin } = await apiClientJava.getAvailableStores();

    dispatch(actions.getAvailableStores.success({ responseData, admin }));
  } catch (err) {
    dispatch(actions.getAvailableStores.failure(err as AxiosError));
  }
};

export const getDatasetInfo: ThunkActionCreator = () => async (
  dispatch,
  _,
  { apiClientJava }
) => {
  dispatch(actions.getDatasetInfo.request());

  try {
    const response = await apiClientJava.getDatasetInfo();

    dispatch(actions.getDatasetInfo.success(response));
  } catch (err) {
    dispatch(actions.getDatasetInfo.failure(err as AxiosError));
  }
};

export const getLatestJobs: ThunkActionCreator<getOptimizationJobParams> = (
  params
) => async (dispatch, _, { apiClientJava }) => {
  dispatch(actions.getLatestJobs.request());

  try {
    const response = await apiClientJava.getLatestOptimizationJob({ storeId: params.storeId });

    if (!response.error && response.responseData) {
      const jobObj = response.responseData.reduce((acc, val) => {
        if (val.status === 'done') {
          return {
            ...acc, [val.aisle_code]: {
              optimizedDate: formatDateToYYYYMMDD(val.updated_at),
              jobId: val.job_id
            }
          };
        }

        return acc;
      }, {} as LatestJobs);

      dispatch(actions.getLatestJobs.success(jobObj));
    }
  } catch (err) {
    dispatch(actions.getLatestJobs.failure(err as AxiosError));
  }
};

export const getAvailableAisles: ThunkActionCreator<GetAvailableAislesParams> = (
  params
) => async (dispatch, _, { apiClientJava }) => {
  dispatch(actions.getAvailableAisles.request());

  try {
    const response = await apiClientJava.getAvailableAisles(params);

    dispatch(actions.getAvailableAisles.success(response));
  } catch (err) {
    dispatch(actions.getAvailableAisles.failure(err as AxiosError));
  }
};

export const getCurrentState: ThunkActionCreator<GetCurrentStateParams> = (
  params
) => async (dispatch, _, { apiClientJava }) => {
  dispatch(actions.getCurrentState.request());

  try {
    const response = await apiClientJava.getCurrentState(params);

    dispatch(actions.getCurrentState.success(response));
  } catch (err) {
    dispatch(actions.getCurrentState.failure(err as AxiosError));
  }
};

export const getOptimizedState: ThunkActionCreator<getOptimizationJobProtoParams> = (
  params
) => async (dispatch, _, { apiClientJava }) => {
  dispatch(actions.getOptimizedState.request());

  try {
    const response = await apiClientJava.getOptimizedState(params);

    dispatch(actions.getOptimizedState.success({ ...response, jobId: params.job_id }));
  } catch (err) {
    dispatch(actions.getOptimizedState.failure(err as AxiosError));
  }
};

export const getCurrentStateProducts: ThunkActionCreator<GetCurrentStateParams> = (
  params
) => async (dispatch, _, { apiClientJava }) => {
  dispatch(actions.getCurrentStateProducts.request());

  try {
    const response = await apiClientJava.getCurrentStateProducts(params);

    dispatch(actions.getCurrentStateProducts.success(response));
  } catch (err) {
    dispatch(actions.getCurrentStateProducts.failure(err as AxiosError));
  }
};

export const getCurrentStateProductsAssortment: ThunkActionCreator<GetCurrentStateParams> = (
  params
) => async (dispatch, _, { apiClientJava }) => {
  dispatch(actions.getCurrentStateProductsAssortment.request());

  try {
    const response = await apiClientJava.getCurrentStateProducts(params);

    dispatch(actions.getCurrentStateProductsAssortment.success(response));
  } catch (err) {
    dispatch(actions.getCurrentStateProductsAssortment.failure(err as AxiosError));
  }
};

export const getParameters: ThunkActionCreator<GetAssortmentParams> = (
  params
) => async (dispatch, _, { apiClientJava }) => {
  dispatch(actions.getAssortmentParams.request());

  try {
    const response = await apiClientJava.getParameters(params);

    dispatch(actions.getAssortmentParams.success(response));
  } catch (err) {
    dispatch(actions.getAssortmentParams.failure(err as AxiosError));
  }
};

export const launchOptimizationJob: ThunkActionCreator<LaunchOptimizationJobParams> = (
  params
) => async (dispatch, _, { apiClientJava }) => {
  dispatch(actions.launchOptimizationJob.request());

  try {
    const response = await apiClientJava.launchOptimizationJob(params);

    dispatch(actions.launchOptimizationJob.success(response));
  } catch (err) {
    dispatch(actions.launchOptimizationJob.failure(err as AxiosError));
  }
};

export const getOptimizationStatus: ThunkActionCreator<GetOptimizationStatusParams> = (
  params
) => async (dispatch, _, { apiClientJava }) => {
  dispatch(actions.getOptimizationStatus.request());

  try {
    const response = await apiClientJava.getOptimizationStatus(params);

    dispatch(actions.getOptimizationStatus.success(response));
  } catch (err) {
    dispatch(actions.getOptimizationStatus.failure(err as AxiosError));
  }
};

export const getOptimizationSolverOutput: ThunkActionCreator<GetSolverOutputParams> = (
  params
) => async (dispatch, _, { apiClientJava }) => {
  dispatch(actions.getOptimizationSolverOutput.request(params));

  try {
    const response = await apiClientJava.getOptimizationSolverOutput(params);

    dispatch(actions.getOptimizationSolverOutput.success(response));
  } catch (err) {
    dispatch(actions.getOptimizationSolverOutput.failure(err as AxiosError));
  }
};

export const getMetricsForJobId: ThunkActionCreator<GetMetricsForJobIdParams> = (
  params
) => async (dispatch, _, { apiClientJava }) => {
  dispatch(actions.getMetricsForJobId.request());

  try {
    const response = await apiClientJava.getMetricsForJobId(params);

    dispatch(actions.getMetricsForJobId.success(response));
  } catch (err) {
    dispatch(actions.getMetricsForJobId.failure(err as AxiosError));
  }
};

export const getAssortment: ThunkActionCreator<GetAssortmentParams> = (
  params
) => async (dispatch, _, { apiClientJava }) => {
  dispatch(actions.getAssortment.request());

  try {
    const response = await apiClientJava.getAssortment(params);

    dispatch(actions.getAssortment.success(response));
  } catch (err) {
    dispatch(actions.getAssortment.failure(err as AxiosError));
  }
};

export const setUserInfo: ThunkActionCreator<AuthenticationResult> = (
  userInfo: AuthenticationResult
) => async (dispatch, _, { apiClientJava }) => {
  const { name, preferred_username: email } = userInfo.idTokenClaims as {
    name: string;
    preferred_username: string;
  };

  dispatch(actions.setUserInfo.request());

  try {
    dispatch(actions.setUserInfo.success({ name, email }));
    apiClientJava.setAccessToken(userInfo.accessToken);
    msalService.setAccessToken(userInfo.accessToken);
  } catch (err) {
    dispatch(actions.setUserInfo.failure(err as AxiosError));
  }
};

export const serverSideLogout: ThunkActionCreator = () => async (
  dispatch,
  _,
  { apiClientJava }
) => {
  dispatch(actions.serverSideLogout.request());

  try {
    await apiClientJava.serverSideLogout();
    dispatch(actions.serverSideLogout.success());
  } catch (err) {
    dispatch(actions.serverSideLogout.failure(err as AxiosError));
  }
};

export const downloadOutputFiles: ThunkActionCreator<DownloadOutputFileParam> =
  (params) =>
    async (dispatch, _, { fetchClientJava }) => {
      dispatch(actions.downloadOutputFiles.request());

      try {
        const response = await fetchClientJava.downloadOutputFiles(params);
        const { includeImages } = params;
        const { status } = response;

        dispatch(actions.downloadOutputFiles.success(includeImages ? status : null));
      } catch (err) {
        dispatch(actions.downloadOutputFiles.failure(err as AxiosError));
      }
    };

export const downloadDOSFile: ThunkActionCreator<DownloadDOSFileParam> =
  (params) =>
    async (dispatch, _, { fetchClientJava }) => {
      dispatch(actions.downloadDOSFile.request());

      try {
        const response = await fetchClientJava.downloadDOSFiles(params);

        dispatch(actions.downloadDOSFile.success(response));
      } catch (err) {
        dispatch(actions.downloadDOSFile.failure(err as AxiosError));
      }
    };

export const downloadAssortmentExcelFile: ThunkActionCreator<DownloadAssortmentExcelFileParam> =
  (params) =>
    async (dispatch, _, { fetchClientJava }) => {
      dispatch(actions.downloadAssortmentExcelFile.request());

      try {
        const response = await fetchClientJava.downloadAssortmentExcelFile(params);

        dispatch(actions.downloadAssortmentExcelFile.success(response));
      } catch (err) {
        dispatch(actions.downloadAssortmentExcelFile.failure(err as AxiosError));
      }
    };

export const getOptimizationJobLimit: ThunkActionCreator<getOptimizationJobParams> = (
  params
) => async (dispatch, _, { apiClientJava }) => {
  dispatch(actions.getOptimizationJobLimit.request());

  try {
    const response = await apiClientJava.getOptimizationJobLimit(params);

    dispatch(actions.getOptimizationJobLimit.success(response));
  } catch (err) {
    dispatch(actions.getOptimizationJobLimit.failure(err as AxiosError));
  }
};

export const getEditedJobs: ThunkActionCreator<GetEditedJobParams> = (
  params
) => async (dispatch, _, { apiClientJava }) => {
  try {
    dispatch(actions.getEditedJobs.request());
    const response = await apiClientJava.getEditedJobs(params);

    dispatch(actions.getEditedJobs.success(response));
  } catch (err) {
    dispatch(actions.getEditedJobs.failure(err as AxiosError));
  }
};

export const getEditedMetricsPog: ThunkActionCreator<GetEditedMetricsPogParams> = (
  params
) => async (dispatch, _, { apiClientJava }) => {
  dispatch(actions.getEditedMetricsPog.request());

  try {
    const response = await apiClientJava.getEditedMetricsPog(params);

    dispatch(actions.getEditedMetricsPog.success({ ...response, jobId: params.jobId }));
  } catch (err) {
    dispatch(actions.getEditedMetricsPog.failure(err as AxiosError));
  }
};

export const getEditedMetricsProduct: ThunkActionCreator<GetEditedMetricsProductParams> = (
  params
) => async (dispatch, _, { apiClientJava }) => {
  dispatch(actions.getEditedMetricsProduct.request());

  try {
    const response = await apiClientJava.getEditedMetricsProduct(params);

    dispatch(actions.getEditedMetricsProduct.success({ ...response, jobId: params.jobId }));
  } catch (err) {
    dispatch(actions.getEditedMetricsProduct.failure(err as AxiosError));
  }
};

export const getEditedSolverOutput: ThunkActionCreator<GetEditedSolverOutputParams> = (
  params
) => async (dispatch, _, { apiClientJava }) => {
  dispatch(actions.getEditedSolverOutput.request(params.jobId));

  try {
    const response = await apiClientJava.getEditedSolverOutput(params);

    dispatch(actions.getEditedSolverOutput.success(response));
  } catch (err) {
    dispatch(actions.getEditedSolverOutput.failure(err as AxiosError));
  }
};

export const saveNewEditedPog: ThunkActionCreator<SaveNewEditedPogParams> = (
  params
) => async (dispatch, _, { apiClientJava }) => {
  try {
    const { storeId, aisleCode, jobId } = params;

    dispatch(actions.saveNewEditedPog.request());
    const response = await apiClientJava.saveNewEditedPog(params);
    const { job_id: responseJobId } = response.job;

    if (jobId) {
      batch(() => {
        dispatch(actions.saveNewEditedPog.success(response));
        dispatch(getEditedMetricsPog({ storeId, aisleCode, jobId: responseJobId }));
        dispatch(getEditedMetricsProduct({ storeId, aisleCode, jobId: responseJobId }));
      });
    }
  } catch (err) {
    dispatch(actions.saveNewEditedPog.failure(err as AxiosError));
  }
};

export const updateEditedPog: ThunkActionCreator<SaveNewEditedPogParams> = (
  params
) => async (dispatch, _, { apiClientJava }) => {
  try {
    const { storeId, aisleCode, jobId } = params;

    dispatch(actions.updateEditedPog.request());
    const response = await apiClientJava.updateEditedPog(params);

    if (jobId) {
      batch(() => {
        dispatch(actions.updateEditedPog.success(response));
        dispatch(getEditedMetricsPog({ storeId, aisleCode, jobId }));
        dispatch(getEditedMetricsProduct({ storeId, aisleCode, jobId }));
      });
    }
  } catch (err) {
    dispatch(actions.updateEditedPog.failure(err as AxiosError));
  }
};

export const getFetchPogByStore: ThunkActionCreator<getFetchPogByStoreParams> = (
  params
) => async (dispatch, _, { apiClientJava }) => {
  dispatch(actions.getFetchPogByStore.request());

  try {
    const response = await apiClientJava.getFetchPogByStore(params);

    dispatch(actions.getFetchPogByStore.success(response));
  } catch (err) {
    dispatch(actions.getFetchPogByStore.failure(err as AxiosError));
  }
};

export const aggregatedJobs: ThunkActionCreator<AggregatedJobsParams> = (
  params
) => async (dispatch, _, { apiClientJava }) => {
  dispatch(actions.aggregatedJobs.request());

  try {
    const response = await apiClientJava.aggregatedJobs(params);

    dispatch(actions.aggregatedJobs.success(response));
  } catch (err) {
    dispatch(actions.aggregatedJobs.failure(err as AxiosError));
  }
};

export const aggregatedSolverOutput: ThunkActionCreator<AggregatedSolverOutputParams> = (
  params
) => async (dispatch, _, { apiClientJava }) => {
  dispatch(actions.aggregatedSolverOutput.request(params));

  try {
    const response = await apiClientJava.aggregatedSolverOutput(params);

    dispatch(actions.aggregatedSolverOutput.success(response));
  } catch (err) {
    dispatch(actions.aggregatedSolverOutput.failure(err as AxiosError));
  }
};

export const aggregatedProductMetrics: ThunkActionCreator<AggregatedProductMetricsParams> = (
  params
) => async (dispatch, _, { apiClientJava }) => {
  dispatch(actions.aggregatedProductMetrics.request(params));

  try {
    const response = await apiClientJava.aggregatedProductMetrics(params);

    dispatch(actions.aggregatedProductMetrics.success(response));
  } catch (err) {
    dispatch(actions.aggregatedProductMetrics.failure(err as AxiosError));
  }
};

export const aggregatedPogMetrics: ThunkActionCreator<AggregatedPogMetricsParams> = (
  params
) => async (dispatch, _, { apiClientJava }) => {
  dispatch(actions.aggregatedPogMetrics.request(params));

  try {
    const response = await apiClientJava.aggregatedPogMetrics(params);

    dispatch(actions.aggregatedPogMetrics.success(response));
  } catch (err) {
    dispatch(actions.aggregatedPogMetrics.failure(err as AxiosError));
  }
};

export const getProductsInfo: ThunkActionCreator<ProductsInfoParams> = (
  params
) => async (dispatch, _, { apiClientJava }) => {
  dispatch(actions.getProductsInfo.request(params));

  try {
    const response = await apiClientJava.getProductsInfo(params);

    dispatch(actions.getProductsInfo.success(response));
  } catch (err) {
    dispatch(actions.getProductsInfo.failure(err as AxiosError));
  }
};

export const getProductsPackaging: ThunkActionCreator<ProductsPackagingParams> = (
  params
) => async (dispatch, _, { apiClientJava }) => {
  dispatch(actions.getProductsPackaging.request(params));

  try {
    const response = await apiClientJava.getProductsPackaging(params);

    dispatch(actions.getProductsPackaging.success(response));
  } catch (err) {
    dispatch(actions.getProductsPackaging.failure(err as AxiosError));
  }
};

export const getUserPreference: ThunkActionCreator = () => async (
  dispatch,
  _,
  { apiClientJava }
) => {
  dispatch(actions.getUserPreference.request());

  try {
    const response = await apiClientJava.getUserPreference();

    dispatch(actions.getUserPreference.success(response));
  } catch (err) {
    dispatch(actions.getUserPreference.failure(err as AxiosError));
  }
};

export const saveUserPreference: ThunkActionCreator<SaveUserPreferenceParams> = (
  params
) => async (dispatch, _, { apiClientJava }) => {
  dispatch(actions.saveUserPreference.request(params));

  try {
    const response = await apiClientJava.saveUserPreference(params);

    dispatch(actions.saveUserPreference.success(response));
  } catch (err) {
    dispatch(actions.saveUserPreference.failure(err as AxiosError));
  }
};

export const saveMyNotes: ThunkActionCreator<SaveMyNotesParams> = (
  params
) => async (dispatch, _, { apiClientJava }) => {
  dispatch(actions.saveMyNotes.request());

  try {
    const response = await apiClientJava.saveMyNotes(params);

    dispatch(actions.saveMyNotes.success(response));
  } catch (err) {
    dispatch(actions.saveMyNotes.failure(err as AxiosError));
  }
};

export const flipPog: ThunkActionCreator<FlipPogParams> = (
  params
) => async (dispatch, _, { apiClientJava }) => {
  dispatch(actions.flipPog.request());

  try {
    const response = await apiClientJava.flipPog(params);

    dispatch(actions.flipPog.success(response));
  } catch (err) {
    dispatch(actions.flipPog.failure(err as AxiosError));
  }
};
