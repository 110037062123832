import { DEFAULT_LANGUAGE, STORAGE_NAMES } from '../utils/constants';
import { userPreferences } from '../state/user/reducer';
import { ApiOptimizationConfigurationRequest } from '../types/api';
import { DROP_EFFECTS } from '../components/ViewAndEdit/editorConstants';
import { getFromSessionStorage, removeFromLocalStorage, setToSessionStorage } from '../utils/sessionStorage.utils';

export const getStoreNumberFromStorage = (): string => getFromSessionStorage(STORAGE_NAMES.STORE_NUMBER) || '';
export const setStoreNumberToStorage = (storeNumber: string | number) => setToSessionStorage(STORAGE_NAMES.STORE_NUMBER, storeNumber);

export const getUserPreferencesFromStorage = (): userPreferences => getFromSessionStorage(STORAGE_NAMES.USER_PREFERENCES) || {} as userPreferences;
export const setUserPreferencesToStorage = (userPreferences: userPreferences) => setToSessionStorage(STORAGE_NAMES.USER_PREFERENCES, userPreferences);

export const getJobIdParamsFromStorage = () => getFromSessionStorage(STORAGE_NAMES.JOB_ID_PARAMS);
export const setJobIdParamsToStorage = (jobIdParams: ApiOptimizationConfigurationRequest) => setToSessionStorage(STORAGE_NAMES.JOB_ID_PARAMS, jobIdParams);
export const removeJobIdParamsFromStorage = () => removeFromLocalStorage(STORAGE_NAMES.JOB_ID_PARAMS);

export const getSelectedJobIdFromStorage = () => getFromSessionStorage(STORAGE_NAMES.SELECTED_JOB_ID);
export const setSelectedJobIdToStorage = (selectedJobId: string) => setToSessionStorage(STORAGE_NAMES.SELECTED_JOB_ID, selectedJobId);

export const getLanguageFromStorage = (): string => getFromSessionStorage(STORAGE_NAMES.I18NEXT_LNG) || DEFAULT_LANGUAGE;

export const setLanguageToStorage = (userPreferences: userPreferences | string) => {
  const lng = typeof userPreferences === 'string' ? userPreferences : userPreferences?.language?.replace(/^"(.*)"$/, '$1') || DEFAULT_LANGUAGE;

  return setToSessionStorage(STORAGE_NAMES.I18NEXT_LNG, lng);
};

export const getUserEmailFromStorage = (): string | null => getFromSessionStorage(STORAGE_NAMES.USER_EMAIL);
export const setUserEmailToStorage = (userEmail: string) => setToSessionStorage(STORAGE_NAMES.USER_EMAIL, userEmail);

export const getDropEffectFromStorage = () => getFromSessionStorage(STORAGE_NAMES.DROP_EFFECT);
export const setDropEffectToStorage = (dropEffect: DROP_EFFECTS) => setToSessionStorage(STORAGE_NAMES.DROP_EFFECT, dropEffect);
