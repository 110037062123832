import { ActionType, getType } from 'typesafe-actions';
import { AxiosResponse } from 'axios';

import { NotificationSpecification } from '../../types/models';
import { actions as apiActions } from '../api/actions';
import { actions } from './actions';
import { generateUniqueId } from '../../helpers/utils';

export type NotificationState = {
  notifications: NotificationSpecification[];
};

const INITIAL_STATE: NotificationState = {
  notifications: [],
};

export function notification(
  state: NotificationState = INITIAL_STATE,
  action: ActionType<typeof apiActions | typeof actions>
): NotificationState {
  // Check for API failures
  const apiFailures = Object.values(apiActions).map((value) =>
    getType(value.failure)
  );

  for (const failure of apiFailures) {
    // Filter out 401 errors, these are handled in a separate user story
    if (action.type === failure && !is401Error(action.payload.response)) {
      // Until we have more robust error handling in the backend, only display 400 error messages.
      /* istanbul ignore next */
      const message =
        action.payload.response?.data?.errorMessage ?? 'An error has occurred';

      return {
        notifications: [
          ...state.notifications,
          withDateAsDefaultKey({
            message: message,
            options: {
              variant: 'error',
            },
          }),
        ],
      };
    }
  }

  switch (action.type) {
    case getType(actions.addNotification): {
      return {
        notifications: [
          ...state.notifications,
          withDateAsDefaultKey(action.payload),
        ],
      };
    }
    case getType(apiActions.saveNewEditedPog.success): {
      return {
        notifications: [
          ...state.notifications,
          withDateAsDefaultKey({
            message: 'The POG has been successfully saved as a new version!',
            options: {
              variant: 'success',
              autoHideDuration: 3000,
            },
          }),
        ],
      };
    }
    case getType(apiActions.saveNewEditedPog.failure): {
      return {
        notifications: [
          ...state.notifications,
          withDateAsDefaultKey({
            message: 'Due a POG data issue, we could not save the POG as a new version. Please undo some recent changes and try again before repeating your edits.',
            options: {
              variant: 'error',
              autoHideDuration: 3000,
            },
          }),
        ],
      };
    }
    case getType(apiActions.updateEditedPog.success): {
      return {
        notifications: [
          ...state.notifications,
          withDateAsDefaultKey({
            message: 'The changes to your POG have been successfully saved!',
            options: {
              variant: 'success',
              autoHideDuration: 3000,
            },
          }),
        ],
      };
    }
    case getType(apiActions.updateEditedPog.failure): {
      return {
        notifications: [
          ...state.notifications,
          withDateAsDefaultKey({
            message: 'Due a POG data issue, we could not save the POG changes. Please undo some recent changes and try again before repeating your edits.',
            options: {
              variant: 'error',
              autoHideDuration: 3000,
            },
          }),
        ],
      };
    }
    case getType(actions.dismissNotification): {
      return {
        notifications: state.notifications.filter(
          (notification) => notification.options.key !== action.payload
        ),
      };
    }
    default:
      return state;
  }
}

const is401Error = (response?: AxiosResponse<any>): boolean => {
  /* istanbul ignore next */
  return response?.status === 401;
};

export const withDateAsDefaultKey: (
  notification: NotificationSpecification
) => NotificationSpecification = (notification) => {
  return {
    ...notification,
    options: {
      ...notification.options,
      key: notification.options.key || generateUniqueId(),
    },
  };
};
