export function getConfig(key: string): string {
  const configValue = process.env[key] ?? window.env[key];

  if (configValue === undefined) {
    throw Error(`Config value not set for ${key}`);
  }

  return configValue;
}

export function getEnv(key: string): string {
  return process.env[key] ?? window.env[key];
}
