import React from 'react';
import { Route, RouteProps, Redirect, useLocation } from 'react-router-dom';

import { AuthRoutes } from '../utils/constants';

interface PrivateRouteProps extends RouteProps {
  component: React.ComponentType<any>;
  isAuthenticated: boolean;
}

type Props = PrivateRouteProps;

//TODO: see here for full implementation
// https://github.com/ElementAI/underwriting-intelligence/blob/master/underwriting-webapp/src/routers/PrivateRoute.tsx

const PrivateRoute = (props: Props) => {
  const { component: Component, isAuthenticated, location, ...rest } = props;
  const locationUrl = useLocation();

  return (
    <Route
      {...rest}
      render={(props): JSX.Element =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to={`${AuthRoutes.Login}?startUrl=${locationUrl.pathname}`} />
        )
      }
    />
  );
};

export default PrivateRoute;
