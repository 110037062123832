import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import {
  GetOptimizationStatusResponse,
  LaunchOptimizationJobResponse,
  GetAssortmentResponse,
  GetParametersResponse,
  GetAvailableStoresResponseWithAdmin,
  GetStateResponse,
  GetAvailableAislesResponse,
  SaveDragAndDropEditsResponse,
  SetUserInfoResponse,
  OptimizationJobResponse,
  GetProductsStateResponse,
  GetMeticForJobIdResponse,
  Default,
  LatestJobs,
  DatasetInfo,
  EditedJobResponse,
  SolverOutputResponse,
  GetEditedMetricsProductResponse,
  GetEditedMetricsPogResponse,
  SaveNewEditedPogResponse,
  GetFetchPogByStoreResponse,
  AggregatedJobsResponse,
  AggregatedSolverOutputResponse,
  AggregatedProductMetricsResponse,
  AggregatedPogMetricsResponse,
  ProductsInfoResponse,
  ProductsPackagingResponse,
  GetUserPreferenceResponse,
  SaveUserPreferenceResponse,
  SaveMyNotesResponse,
  FlipPogResponse,
  PDFDownloadStatus,
} from '../../types/responses';
import {
  GetSolverOutputParams,
  AggregatedSolverOutputParams,
  AggregatedProductMetricsParams,
  AggregatedPogMetricsParams,
  ProductsInfoParams,
  ProductsPackagingParams,
  SaveUserPreferenceParams
} from '../../types/requests';

export const actions = {

  getAssortmentParams: createAsyncAction(
    'api/GET_PARAMETERS_REQUEST',
    'api/GET_PARAMETERS_SUCCESS',
    'api/GET_PARAMETERS_FAILURE'
  )<void, GetParametersResponse, AxiosError>(),

  launchOptimizationJob: createAsyncAction(
    'api/LAUNCH_OPTIMIZATION_JOB_REQUEST',
    'api/LAUNCH_OPTIMIZATION_JOB_SUCCESS',
    'api/LAUNCH_OPTIMIZATION_JOB_FAILURE'
  )<void, LaunchOptimizationJobResponse, AxiosError>(),

  getOptimizationStatus: createAsyncAction(
    'api/GET_OPTIMIZATION_STATUS_REQUEST',
    'api/GET_OPTIMIZATION_STATUS_SUCCESS',
    'api/GET_OPTIMIZATION_STATUS_FAILURE'
  )<void, GetOptimizationStatusResponse, AxiosError>(),

  getDatasetInfo: createAsyncAction(
    'api/GET_DATASET_INFO_REQUEST',
    'api/GET_DATASET_INFO_SUCCESS',
    'api/GET_DATASET_INFO_FAILURE'
  )<void, DatasetInfo, AxiosError>(),

  getAssortment: createAsyncAction(
    'api/GET_ASSORTMENT_REQUEST',
    'api/GET_ASSORTMENT_SUCCESS',
    'api/GET_ASSORTMENT_FAILURE'
  )<void, GetAssortmentResponse, AxiosError>(),

  getAvailableStores: createAsyncAction(
    'api/GET_AVAILABLE_STORES_REQUEST',
    'api/GET_AVAILABLE_STORES_SUCCESS',
    'api/GET_AVAILABLE_STORES_FAILURE'
  )<void, GetAvailableStoresResponseWithAdmin, AxiosError>(),

  getAvailableAisles: createAsyncAction(
    'api/GET_AVAILABLE_AISLES_REQUEST',
    'api/GET_AVAILABLE_AISLES_SUCCESS',
    'api/GET_AVAILABLE_AISLES_FAILURE'
  )<void, GetAvailableAislesResponse, AxiosError>(),

  getLatestJobs: createAsyncAction(
    'api/GET_LATEST_JOBS_REQUEST',
    'api/GET_LATEST_JOBS_SUCCESS',
    'api/GET_LATEST_JOBS_FAILURE'
  )<void, LatestJobs, AxiosError>(),

  serverSideLogout: createAsyncAction(
    'api/SERVER_SIDE_LOGOUT_REQUEST',
    'api/SERVER_SIDE_LOGOUT_SUCCESS',
    'api/SERVER_SIDE_LOGOUT_FAILURE'
  )<void, void, AxiosError>(),

  setUserInfo: createAsyncAction(
    'user/SET_USER_INFO_REQUEST',
    'user/SET_USER_INFO_SUCCESS',
    'user/SET_USER_INFO_FAILURE'
  )<void, SetUserInfoResponse, AxiosError>(),

  getCurrentState: createAsyncAction(
    'api/GET_CURRENT_STATE_REQUEST',
    'api/GET_CURRENT_STATE_SUCCESS',
    'api/GET_CURRENT_STATE_FAILURE'
  )<void, GetStateResponse, AxiosError>(),

  getOptimizedState: createAsyncAction(
    'api/GET_OPTIMIZED_STATE_REQUEST',
    'api/GET_OPTIMIZED_STATE_SUCCESS',
    'api/GET_OPTIMIZED_STATE_FAILURE'
  )<void, GetStateResponse, AxiosError>(),

  getCurrentStateProducts: createAsyncAction(
    'api/GET_CURRENT_STATE_PRODUCTS_REQUEST',
    'api/GET_CURRENT_STATE_PRODUCTS_SUCCESS',
    'api/GET_CURRENT_STATE_PRODUCTS_FAILURE'
  )<void, GetProductsStateResponse, AxiosError>(),

  getCurrentStateProductsAssortment: createAsyncAction(
    'api/GET_CURRENT_STATE_PRODUCTS_ASSORTMENT_REQUEST',
    'api/GET_CURRENT_STATE_PRODUCTS_ASSORTMENT_SUCCESS',
    'api/GET_CURRENT_STATE_PRODUCTS_ASSORTMENT_FAILURE'
  )<void, GetProductsStateResponse, AxiosError>(),

  downloadOutputFiles: createAsyncAction(
    'api/GET_DOWNLOAD_OUTPUT_REQUEST',
    'api/GET_DOWNLOAD_OUTPUT_SUCCESS',
    'api/GET_DOWNLOAD_OUTPUT_FAILURE'
  )<void, null | PDFDownloadStatus, AxiosError>(),

  downloadDOSFile: createAsyncAction(
    'api/GET_DOWNLOAD_DOS_REQUEST',
    'api/GET_DOWNLOAD_DOS_SUCCESS',
    'api/GET_DOWNLOAD_DOS_FAILURE'
  )<void, void, AxiosError>(),

  downloadAssortmentExcelFile: createAsyncAction(
    'api/DOWNLOAD_ASSORTMENT_EXCEL_FILE_REQUEST',
    'api/DOWNLOAD_ASSORTMENT_EXCEL_FILE_SUCCESS',
    'api/DOWNLOAD_ASSORTMENT_EXCEL_FILE_FAILURE'
  )<void, void, AxiosError>(),

  saveDragAndDropEdits: createAsyncAction(
    'api/SAVE_DRAG_AND_DROP_EDITS_REQUEST',
    'api/SAVE_DRAG_AND_DROP_EDITS_SUCCESS',
    'api/SAVE_DRAG_AND_DROP_EDITS_FAILURE'
  )<void, SaveDragAndDropEditsResponse, AxiosError>(),

  getOptimizationJobLimit: createAsyncAction(
    'api/GET_OPTIMIZATION_JOB_LIMIT_REQUEST',
    'api/GET_OPTIMIZATION_JOB_LIMIT_SUCCESS',
    'api/GET_OPTIMIZATION_JOB_LIMIT_FAILURE'
  )<void, OptimizationJobResponse, AxiosError>(),

  getOptimizationSolverOutput: createAsyncAction(
    'api/GET_OPTIMIZATION_SOLVER_OUTPUT_REQUEST',
    'api/GET_OPTIMIZATION_SOLVER_OUTPUT_SUCCESS',
    'api/GET_OPTIMIZATION_SOLVER_OUTPUT_FAILURE'
  )<GetSolverOutputParams, SolverOutputResponse, AxiosError>(),

  getMetricsForJobId: createAsyncAction(
    'api/GET_METRIC_JOBID_REQUEST',
    'api/GET_METRIC_JOBID_SUCCESS',
    'api/GET_METRIC_JOBID_FAILURE'
  )<void, GetMeticForJobIdResponse, AxiosError>(),

  getEditedJobs: createAsyncAction(
    'api/GET_EDITED_JOBS_REQUEST',
    'api/GET_EDITED_JOBS_SUCCESS',
    'api/GET_EDITED_JOBS_FAILURE'
  )<void, EditedJobResponse, AxiosError>(),

  getEditedSolverOutput: createAsyncAction(
    'api/GET_EDITED_SOLVER_OUTPUT_REQUEST',
    'api/GET_EDITED_SOLVER_OUTPUT_SUCCESS',
    'api/GET_EDITED_SOLVER_OUTPUT_FAILURE'
  )<string, SolverOutputResponse, AxiosError>(),

  getEditedMetricsProduct: createAsyncAction(
    'api/GET_EDITED_METRICS_PRODUCT_REQUEST',
    'api/GET_EDITED_METRICS_PRODUCT_SUCCESS',
    'api/GET_EDITED_METRICS_PRODUCT_FAILURE'
  )<void, GetEditedMetricsProductResponse, AxiosError>(),

  getEditedMetricsPog: createAsyncAction(
    'api/GET_EDITED_METRICS_POG_REQUEST',
    'api/GET_EDITED_METRICS_POG_SUCCESS',
    'api/GET_EDITED_METRICS_POG_FAILURE'
  )<void, GetEditedMetricsPogResponse, AxiosError>(),

  saveNewEditedPog: createAsyncAction(
    'api/SAVE_NEW_EDITED_POG_REQUEST',
    'api/SAVE_NEW_EDITED_POG_SUCCESS',
    'api/SAVE_NEW_EDITED_POG_FAILURE'
  )<void, SaveNewEditedPogResponse, AxiosError>(),

  saveMyNotes: createAsyncAction(
    'api/SAVE_MY_NOTES_REQUEST',
    'api/SAVE_MY_NOTES_SUCCESS',
    'api/SAVE_MY_NOTES_FAILURE'
  )<void, SaveMyNotesResponse, AxiosError>(),

  updateEditedPog: createAsyncAction(
    'api/UPDATE_EDITED_POG_REQUEST',
    'api/UPDATE_EDITED_POG_SUCCESS',
    'api/UPDATE_EDITED_POG_FAILURE'
  )<void, SaveNewEditedPogResponse, AxiosError>(),

  default: createAsyncAction(
    'api/DEFAULT_REQUEST',
    'api/DEFAULT_SUCCESS',
    'api/DEFAULT_FAILURE'
  )<void, Default, AxiosError>(),

  getFetchPogByStore: createAsyncAction(
    'api/GET_FETCH_POG_BY_STORE_PARAM_REQUEST',
    'api/GET_FETCH_POG_BY_STORE_PARAMSUCCESS',
    'api/GET_FETCH_POG_BY_STORE_PARAMFAILURE'
  )<void, GetFetchPogByStoreResponse, AxiosError>(),

  aggregatedJobs: createAsyncAction(
    'api/AGGREGATED_JOBS_REQUEST',
    'api/AGGREGATED_JOBS_SUCCESS',
    'api/AGGREGATED_JOBS_FAILURE'
  )<void, AggregatedJobsResponse, AxiosError>(),

  aggregatedSolverOutput: createAsyncAction(
    'api/AGGREGATED_SOLVER_OUTPUT_REQUEST',
    'api/AGGREGATED_SOLVER_OUTPUT_SUCCESS',
    'api/AGGREGATED_SOLVER_OUTPUT_FAILURE'
  )<AggregatedSolverOutputParams, AggregatedSolverOutputResponse, AxiosError>(),

  aggregatedProductMetrics: createAsyncAction(
    'api/AGGREGATED_PRODUCT_METRICS_REQUEST',
    'api/AGGREGATED_PRODUCT_METRICS_SUCCESS',
    'api/AGGREGATED_PRODUCT_METRICS_FAILURE'
  )<AggregatedProductMetricsParams, AggregatedProductMetricsResponse, AxiosError>(),

  aggregatedPogMetrics: createAsyncAction(
    'api/AGGREGATED_POG_METRICS_REQUEST',
    'api/AGGREGATED_POG_METRICS_SUCCESS',
    'api/AGGREGATED_POG_METRICS_FAILURE'
  )<AggregatedPogMetricsParams, AggregatedPogMetricsResponse, AxiosError>(),

  getProductsInfo: createAsyncAction(
    'api/GET_PRODUCTS_INFO_REQUEST',
    'api/GET_PRODUCTS_INFO_SUCCESS',
    'api/GET_PRODUCTS_INFO_FAILURE'
  )<ProductsInfoParams, ProductsInfoResponse, AxiosError>(),

  getProductsPackaging: createAsyncAction(
    'api/GET_PRODUCTS_PACKAGING_REQUEST',
    'api/GET_PRODUCTS_PACKAGING_SUCCESS',
    'api/GET_PRODUCTS_PACKAGING_FAILURE'
  )<ProductsPackagingParams, ProductsPackagingResponse, AxiosError>(),

  getUserPreference: createAsyncAction(
    'api/GET_USER_PREFERENCE_REQUEST',
    'api/GET_USER_PREFERENCE_SUCCESS',
    'api/GET_USER_PREFERENCE_FAILURE'
  )<void, GetUserPreferenceResponse, AxiosError>(),

  saveUserPreference: createAsyncAction(
    'api/SAVE_USER_PREFERENCE_REQUEST',
    'api/SAVE_USER_PREFERENCE_SUCCESS',
    'api/SAVE_USER_PREFERENCE_FAILURE'
  )<SaveUserPreferenceParams, SaveUserPreferenceResponse, AxiosError>(),

  flipPog: createAsyncAction(
    'api/FLIP_POG_REQUEST',
    'api/FLIP_POG_SUCCESS',
    'api/FLIP_POG_FAILURE'
  )<void, FlipPogResponse, AxiosError>(),

};
