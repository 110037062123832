import queryString from 'query-string';

import {
  OptimizationConfiguration,
  AssortmentItemOptimized,
  NewShelfBlock,
  DemandOptions,
  MetricJobs,
  MetricJob,
  TargetBincap,
} from '../types/models';
import { LaunchOptimizationJobParams } from '../types/requests';
import {
  ApiAssortmentItem,
  ApiAssortment,
  ApiOptimizationConfiguration
} from '../types/api';
import {
  AisleMetricsProperties,
  AvailableSoltionProperties,
  GetAssortmentResponse,
  GetAvailbleSolutions,
  GetStateResponse,
  ProducsCorpStatus,
  SolverOutputResponse,
} from '../types/responses';
import type { Jobs } from '../state/job/types';
import { generateUniqueId, pegboardHaloSetting } from './utils';
import { ILang } from '../types/common';

export const formatAilseSetup = (shelf: NewShelfBlock | undefined): string => {
  if (shelf) {
    return `${shelf.num_segments} x ${shelf.height} x ${shelf.depth}`;
  } else {
    return '-';
  }
};

export const createNewJobRequestObject = (
  name: string,
  startingAssortment: string,
  storeNumber: string,
  aisleCode: string,
  optimizationConfiguration: OptimizationConfiguration,
  productItems: string[],
  timeLimit: number,
  targetBincaps: Array<TargetBincap>,
  language: ILang,
): LaunchOptimizationJobParams => {
  const {
    demand,
    includeISS,
    includePromo,
    labels,
    orientation,
    shelfSetup,
    mirroringHeight,
    includeDisplay,
    enableAssortmentSweep,
    haloPegboard,
  } = optimizationConfiguration;
  const halo = pegboardHaloSetting[haloPegboard];
  const {
    inter_sku_spacing_pegboard,
    inter_facing_vertical_spacing_pegboard,
    inter_facing_spacing_pegboard,
  } = halo;

  return {
    name,
    parameters: {
      starting_assortment: startingAssortment,
      store_num: storeNumber,
      aisle_code: aisleCode,
      side: orientation,
      demand,
      labels,
      use_iss: includeISS,
      use_prom: includePromo,
      minimum_reserved_stock: 0,
      mirroring_height: mirroringHeight,
      aisle_geometry: {
        blocks: shelfSetup,
      },
      time_limit_in_seconds: timeLimit,
      include_display: includeDisplay,
      enable_assortment_sweep: enableAssortmentSweep,
      pegboard_spacing: {
        inter_sku_spacing_pegboard,
        inter_facing_vertical_spacing_pegboard,
        inter_facing_spacing_pegboard,
      },
    },
    product_nums: productItems,
    constraints: {
      enforced_bincaps: targetBincaps
    },
    language
  };
};

export const convertAssortment = (
  apiModel: ApiAssortmentItem,
  crossMerch: Array<string>
): AssortmentItemOptimized => {
  return {
    sku: apiModel.product_num,
    description: apiModel.product_english_desc,
    productEnglishLongDesc: apiModel.product_english_long_desc,
    corpStatus: apiModel.corporate_status_cd,
    storeStatus: apiModel.store_product_status,
    totalCommitments: apiModel.total_commitments,
    dmoq: apiModel.dmoq,
    primaryLocation: apiModel.primary_locations,
    secondaryLocations: apiModel.secondary_locations,
    promotable: apiModel.is_promotable,
    historic: apiModel.hist_total_sales,
    campsUnitAnnul: apiModel.camps_sales,
    lostSalesUnits: apiModel.lost_sales_total,
    lostSales$$: apiModel.lost_sales_total_dollar,
    histTotalSalesDollar: apiModel.hist_total_sales_dollar,
    totalForecast: apiModel.total_forecast,
    forecast: apiModel.total_forecast_dollar,
    ofPromo: apiModel.pct_promo_fcst,
    pctPromoHist: apiModel.pct_promo_hist,
    dgm: apiModel.dgm_percentage,
    permanentCount: apiModel.permanent_count,
    crossMerch: crossMerch && crossMerch.includes(apiModel.product_num) ? 'YES' : 'NO',

    isNewSku: apiModel.is_new_sku,
    opportunitySkuFlag: apiModel.opportunity_sku_flag,
    isExtendedAssortment: apiModel.is_extended_assortment ? 'YES' : 'NO',

    avgReg: apiModel.avg_reg_fcst,
    avgRegIss: apiModel.avg_reg_iss_fcst,
    avgRegPromo: apiModel.avg_reg_promo_fcst,
    avgRegPromoIss: apiModel.avg_reg_promo_iss_fcst,
    peakReg: apiModel.peak_reg_fcst,
    peakRegIss: apiModel.peak_reg_iss_fcst,
    peakRegPromo: apiModel.peak_reg_promo_fcst,
    peakRegPromoIss: apiModel.peak_reg_promo_iss_fcst,

    facings: apiModel.facings,
    q1f: apiModel.q1f,
    bincap: apiModel.bincap,
    fillTrips: apiModel.fill_trips,
    wos: apiModel.wos,

    salesForecasts: {
      avgReg: apiModel.avg_reg_fcst,
      avgRegPromo: apiModel.avg_reg_promo_fcst,
      avgRegIss: apiModel.avg_reg_iss_fcst,
      avgRegPromoIss: apiModel.avg_reg_promo_iss_fcst,
      peakReg: apiModel.peak_reg_fcst,
      peakRegPromo: apiModel.peak_reg_promo_fcst,
      peakRegIss: apiModel.peak_reg_iss_fcst,
      peakRegPromoIss: apiModel.peak_reg_promo_iss_fcst,
    },
  };
};

export const convertMetricsResponse = (
  apiModel: GetStateResponse
): AisleMetricsProperties => ({
  maxHeight: apiModel.max_of_height,
  maxDepth: apiModel.max_of_depth,
  linearFeet: apiModel.linear_feet,
  surfaceArea: apiModel.surface_area,
  cubicSpace: apiModel.cubic_space,
  absorption: +apiModel.absorption.toFixed(2),
  merchandisingArea: +apiModel.merchandising_area.toFixed(2),
  fillTrips: apiModel.fill_trips,
  minWeekSupplyQuantiles: {
    first: +apiModel.min_week_supply_quantiles['0.0']?.toFixed(2),
    second: +apiModel.min_week_supply_quantiles['0.25']?.toFixed(2),
    third: +apiModel.min_week_supply_quantiles['0.5']?.toFixed(2),
    forth: +apiModel.min_week_supply_quantiles['0.75']?.toFixed(2),
  },
  productsByCorpStatus: apiModel.products_by_corp_status,
  productsByFacings: {
    oneFacing: apiModel.products_by_facings['1 facing'],
    twoOrMoreFacings: apiModel.products_by_facings['2+ facings'],
  },
  productsWithPrimaryFillIssues: apiModel.products_with_pfi,
  productsWithPfiByCorpStatus: apiModel.products_with_pfi_by_corp_status,
});

export const convertAvailbleSolutions = (
  apiModel: GetAvailbleSolutions
): AvailableSoltionProperties => ({
  aisleCode: apiModel.aisle_code,
  createdAt: apiModel.created_at,
  jobId: apiModel.job_id,
  type: apiModel.type,
});

export const convertOptimizationParameters = (
  apiModel: ApiOptimizationConfiguration
): OptimizationConfiguration => ({
  selectedOptimizationType: apiModel.optimizationType,
  orientation: apiModel.orientation,
  demand: apiModel.demand,
  labels: apiModel.labels,
  mirroringHeight: apiModel.mirroring_height,
  includeISS: apiModel.use_iss,
  includePromo: apiModel.use_prom,
  weeksOfSupply: apiModel.WeeksOfSupply || 4, //TODO: this default should be set in the backend: CTC1-535
  shelfSetup: apiModel.aisleSetup || [],
  // minimumReservedStock: apiModel.minimum_reserved_stock,
  includeDisplay: true,
  vendorPegboard: null,
  haloPegboard: 'defaultPadding',
  enableAssortmentSweep: true
});

const changeFormatIfDayFirst = (dateString: string, monthIsFirstFormat: boolean = true) => {
  if (monthIsFirstFormat) {
    return dateString;
  }

  const parts = dateString.split('/');

  return `${parts[1]}/${parts[0]}/${parts[2]}`;
};

export const formatDateToLocalTimeZone = (date: string, monthIsFirstFormat?: boolean) => `${new Date (`${changeFormatIfDayFirst(date, monthIsFirstFormat)}Z`).toLocaleDateString('en-gb', { day: '2-digit', month: '2-digit', year: 'numeric' })}`;

export const formatDateToLocalTimeString = (date: string, monthIsFirstFormat?: boolean) => `${new Date (`${changeFormatIfDayFirst(date, monthIsFirstFormat)}Z`).toLocaleTimeString('en-gb', { hour12: false })}`;

export const formatDateToYYYYMMDD = (date: string) => new Date(date).toISOString().slice(0, 10).replace(/-/g, '.');

export const range = (n: number) => Array.from({ length: n }, (x, i) => i);

export const rangeFromTo = (from: number, to: number) => Array.from({ length: (to - from) + 1 }, (value, index) => from + index);

export const getInactiveSkus = (corpStatus: Partial<ProducsCorpStatus>): number => {
  const { FD = 0, DWO = 0 } = corpStatus;

  return FD + DWO;
};

export const getActiveSkus = (corpStatus: Partial<ProducsCorpStatus>): number => {
  const { ACT = 0, TD = 0, SD = 0 } = corpStatus;

  return ACT + TD + SD;
};

export const getActiveFD_DWO = (corpStatus: Partial<ProducsCorpStatus>): number => {
  const { FD = 0, DWO = 0 } = corpStatus;

  return FD + DWO;
};

export const getSalesType = (isPromo: boolean, isIss: boolean): string => {
  const promo = isPromo ? 'Promo' : '';
  const iss = isIss ? 'ISS' : '';
  const regular = 'Reg';

  return `${[promo, iss, regular].filter(Boolean).join('+')}`;
};

export const getDemandType = (type: DemandOptions): string => {
  if (type === DemandOptions.Peak) {
    return 'Peak';
  } else if (type === DemandOptions.Average) {
    return 'Average';
  }

  return '';
};

export const isValidWos = (wos: string | number) => !('' + wos).includes('.') && Number(wos) > 0;

export const selectAssortment = (solverOutput: Partial<SolverOutputResponse>, assortmentApi: Partial<ApiAssortment>): string[] => {
  /* istanbul ignore next */
  const sa = solverOutput?.solver_input?.parameters?.starting_assortment || '';
  let all: string[] = [];

  if (sa === 'store_default') {
    all = assortmentApi?.store_default || [];
  } else {
    const [orientation, labelType, size] = sa?.split('.') || [];

    if ((orientation === 'left' || orientation === 'right') && (labelType === 'esl' || labelType === 'regular') && (size === 'A' || size === 'B' || size === 'C' || size === 'D' || size === 'E')) {
      all = assortmentApi?.protos?.[labelType][size] || [];
    }
  }

  return all;
};

export const getFillTripsNumber = (jobId: string, metricJobs: MetricJobs) => {
  try {
    /* istanbul ignore next */
    return metricJobs[jobId] ? (Object.values(metricJobs[jobId].fill_trips).reduce((a, b) => a + b))?.toFixed(2) : '-';
  } catch (e) {
    return '-';
  }
};

export const getFillTripsPerCube = (fillTrips: number, shelf: NewShelfBlock | null) => {
  return (fillTrips || fillTrips == 0) && shelf && shelf.num_segments && shelf.height && shelf.depth ? (fillTrips / ((shelf.num_segments * 4) * (shelf.height / 12) * (shelf.depth / 12))).toFixed(2) : '-';
};

export const getFillTrips$ = (jobId: string, metricJobs: MetricJobs) => {
  return metricJobs[jobId] && (Object.values(metricJobs[jobId].fill_trips).reduce((a, b) => a + b) / Object.keys(metricJobs).length)?.toFixed(2);
};

export const wosAvg = (jobId: string, metricJobs: MetricJobs) => {
  try {
    return metricJobs[jobId] ? (Object.values(metricJobs[jobId].wos).reduce((a, b) => a + b) / Object.keys(metricJobs[jobId].wos).length)?.toFixed(2) : '-';
  } catch (e) {
    return '-';
  }
};

export const getCurrentSetupWosAvg = (metricJob: MetricJob) => {
  try {
    return Object.keys(metricJob.wos).length > 0
      ? (
        Object.values(metricJob.wos).reduce((a, b) => a + b) /
        Object.keys(metricJob.wos).length
      )?.toFixed(2)
      : '-';
  } catch (e) {
    return '-';
  }
};

export const parseWOSSectionData = (metricJobs: MetricJob) => {

  const range: { 1: string[], 2: string[], 3: string[], 4: string[] } = { 1: [], 2: [], 3: [], 4: [] };
  const wosAvgCount: { 1: number, 2: number, 3: number, 4: number } = { 1: 0, 2: 0, 3: 0, 4: 0 };
  const fillTripCount: { 1: number, 2: number, 3: number, 4: number } = { 1: 0, 2: 0, 3: 0, 4: 0 };
  const facing: { 1: number | undefined, 2: number | undefined, 3: number | undefined, 4: number | undefined } = { 1: 0, 2: 0, 3: 0, 4: 0 };

  if (!metricJobs) return { range, wosAvgCount, fillTripCount, facing };
  const wos = metricJobs.wos;

  for (const [key, value] of Object.entries(wos)) {
    if (value <= 2) {
      range[1].push(key);
    }

    if (value > 2 && value <= 5) {
      range[2].push(key);
    }

    if (value > 5 && value < 52) {
      range[3].push(key);
    }

    if (value >= 52) {
      range[4].push(key);
    }
  }

  ['1', '2', '3', '4'].forEach((e) => {
    const key = e as '1' | '2' | '3' | '4';

    wosAvgCount[key] = getWOSAvg(range[key], metricJobs);
    fillTripCount[key] = getFillTripCount(range[key], metricJobs);
    const facings = getFacing(range[key], metricJobs);

    facing[key] = facings === 0 ? undefined : facings;
  });

  return { range, wosAvgCount, fillTripCount, facing };
};

export const getWOSAvg = (skus: string[], metricJobs: MetricJob) => {
  const wos = metricJobs.wos;
  const wosSum = skus.reduce((prev, cur) => prev + (wos[cur] ?? 0), 0);

  if (wosSum) {
    return Number((wosSum / skus.length).toFixed(2));
  }

  return wosSum;
};

export const getFillTripCount = (skus: string[], metricJobs: MetricJob) => {
  const fillTrips = metricJobs.fill_trips;

  /* istanbul ignore next */
  return Number((skus.reduce((prev, cur) => prev + (fillTrips[cur] ?? 0), 0)).toFixed(2));
};

export const getFacing = (skus: string[], metricJobs: MetricJob) => {
  const facings = metricJobs.facings;

  return skus.filter(s => facings[s] > 1).length;
};

export const solverOutputGenerateId = (solverOutput: SolverOutputResponse) => ({
  ...solverOutput,
  planogram: {
    ...solverOutput.planogram,
    facing_groups:
      solverOutput.planogram &&
      Array.isArray(solverOutput.planogram.facing_groups) &&
      solverOutput.planogram.facing_groups?.map((facingGroup) => ({
        ...facingGroup,
        id: generateUniqueId()
      }))
  }
});

type JobsGetParams = {
  jobIds: Array<string>;
  jobEditedIds: Array<string>;
};

export const jobsGetParams = (jobs: Jobs) => {
  const { jobIds, jobEditedIds } = jobs.reduce<JobsGetParams>((acc, job) => {
    if (job.type === 'optimized') {
      return {
        ...acc,
        jobIds: acc.jobIds.concat(job.job_id),
      };
    }

    if (job.type === 'edited') {
      return {
        ...acc,
        jobEditedIds: acc.jobEditedIds.concat(job.job_id),
      };
    }

    return acc;
  }, {
    jobIds: [],
    jobEditedIds: [],
  });
  const jobsParams = queryString.stringify({
    job_ids: jobIds,
    job_editedIds: jobEditedIds,
  }, { arrayFormat: 'comma' });

  return jobsParams;
};

export const mergeCurrentStateProduct = (assortment: AssortmentItemOptimized[], metricsProductStoreInfo: MetricJob) => {

  const tc = metricsProductStoreInfo.total_commitments;
  const facing = metricsProductStoreInfo.facings;
  const q1f = metricsProductStoreInfo.q1f;
  const bincap = metricsProductStoreInfo.bincap;
  const wos = metricsProductStoreInfo.wos;
  const ft = metricsProductStoreInfo.fill_trips;

  Object.keys(tc).forEach((tcom, i) => {
    const ast = assortment.find(a => a.sku === tcom);

    if (ast) {
      ast.totalCommitments = tc[tcom];
    }
  });

  Object.keys(facing).forEach((f, i) => {
    const ast = assortment.find(a => a.sku === f);

    if (ast) {
      ast.facings = facing[f];
    }
  });

  Object.keys(q1f).forEach((q, i) => {
    const ast = assortment.find(a => a.sku === q);

    if (ast) {
      ast.q1f = q1f[q];
    }
  });

  Object.keys(bincap).forEach((b, i) => {
    const ast = assortment.find(a => a.sku === b);

    if (ast) {
      ast.bincap = bincap[b];
    }
  });

  Object.keys(wos).forEach((w, i) => {
    const ast = assortment.find(a => a.sku === w);

    if (ast) {
      ast.wos = wos[w];
    }
  });

  Object.keys(ft).forEach((f, i) => {
    const ast = assortment.find(a => a.sku === f);

    if (ast) {
      ast.fillTrips = ft[f];
    }
  });

  return assortment;
};

export const getBaseAssortment = (apiResponse: GetAssortmentResponse, currentStateProduct: MetricJob) => {
  const { assortment, assortmentApi, currentTotalFacings, forecastInfo, metricsProductStoreInfo, totalCommitments } = apiResponse;
  const { cross_merch: crossMerch } = assortmentApi;
  const baseAssortment = mergeCurrentStateProduct(
    assortment.map((p) => convertAssortment(p, crossMerch)),
    currentStateProduct
  );
  const baseAssortmentWithTargetBincap = baseAssortment.map(a => {
    if (['FD', 'DWO'].includes(a.corpStatus)) {
      return {
        ...a,
        minTargetBincap: null,
        maxTargetBincap: a.totalCommitments > 0 ? a.totalCommitments : null
      };
    }

    return a;
  });

  return { assortmentApi, totalCommitments, forecastInfo, baseAssortmentWithTargetBincap, metricsProductStoreInfo, currentTotalFacings };
};