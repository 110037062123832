import * as React from 'react';
import { Box, createStyles, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import loaderShort from '../../img/loading_short.gif';
import loaderLong from '../../img/loading_long.gif';

type Props = {
  type?: keyof typeof loaders;
  className?: string;
};

const loaders = {
  shortProcess: loaderShort,
  longProcess: loaderLong
};

const useStyles = makeStyles((theme) =>
  createStyles({
    spinnerOverlay: {
      position: 'fixed',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
    }
  }),
);

const Loading = ({ type = 'shortProcess', className }: Props ) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.spinnerOverlay} />
      <Box
        display="flex"
        flex="1 1 auto"
        justifyContent="center"
        alignItems="center"
        padding={3}
        className={clsx(className)}
      >
        <img src={loaders[type]} alt="loader" style={{ maxHeight: '100%' }}/>
      </Box>
    </>
  );
};

export default Loading;
