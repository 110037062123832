import { ActionType, getType } from 'typesafe-actions';

import { DemandOptions, LabelType, OptimizationConfiguration, OptimizationType, Orientation } from '../../types/models';
import { actions as apiActions } from '../api/actions';

export type OptimizationConfigurationState = {
  loaded: boolean,
  aisleData: {
    aisleCode: string,
    storeNumber: number,
  },
  optimizationParameters: OptimizationConfiguration,
};

export const INITIAL_STATE: OptimizationConfigurationState = {
  loaded: false,
  aisleData: {
    aisleCode: '',
    storeNumber: 0,
  },
  optimizationParameters: {
    selectedOptimizationType: OptimizationType.BySpace,
    demand: DemandOptions.Average,
    labels: LabelType.ESL,
    orientation: Orientation.Left,
    includeISS: false,
    includePromo: false,
    weeksOfSupply: 0,
    shelfSetup: [],
    mirroringHeight: 74,
    includeDisplay: true,
    vendorPegboard: null,
    haloPegboard: 'defaultPadding',
    enableAssortmentSweep: false
  }
};

export function optimizationConfiguration(
  state: OptimizationConfigurationState = INITIAL_STATE,
  action: ActionType<typeof apiActions>
): OptimizationConfigurationState {
  switch (action.type) {
    case getType(apiActions.getAssortmentParams.request): {
      return INITIAL_STATE;
    }
    case getType(apiActions.getAssortmentParams.success): {
      const { aisle_code: aisleCode, aisle_geometry, demand, mirroring_height: mirroringHeight, side: orientation, store_num: storeNumber, use_iss: includeISS, use_prom: includePromo }: any = action.payload;

      if (aisle_geometry?.blocks[0].width === 0) {
        aisle_geometry.blocks[0].width = 48;
      }

      return {
        ...state,
        loaded: true,
        aisleData: {
          aisleCode,
          storeNumber,
        },
        optimizationParameters: {
          ...state.optimizationParameters,
          orientation,
          demand,
          includeISS,
          includePromo,
          weeksOfSupply: 0,
          mirroringHeight,
          shelfSetup: aisle_geometry.blocks,
        }
      };
    }
  }

  return state;
}
