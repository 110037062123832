import { createAction } from 'typesafe-actions';

import { MetricJobs } from '../../types/models';

export const actions = {
  setInitialState: createAction('comparison/SET_INITIAL_STATE')<undefined>(),
  setPostSolver: createAction('comparison/SET_POST_SOLVER')<boolean>(),
  setSelectedPogVersions: createAction('comparison/SET_SELECTED_POG_VERSIONS')<{[key: string]: boolean}>(),
  setCurrentSelected: createAction('comparison/SET_CURRENT_SELECTED')<boolean>(),
  setVersionForReview: createAction('comparison/SET_VERSION_FOR_REVIEW')<string>(),
  setMetricJobs: createAction('comparison/SET_METRIC_JOBS')<MetricJobs>(),
};
